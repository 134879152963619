.autocomplete-suggestions {  border-top: 1px solid #d9d9d9; background: #FFF; cursor: default; overflow: auto; box-shadow: 0 2px 6px rgba(0,0,0,0.3);border-radius: 4px;margin-top:4px; }
.autocomplete-suggestion {
	color: #000;
	font-size:rem-calc(14);
	line-height:1.1;
	//white-space: nowrap;
	overflow: hidden;
	padding-left:4px;
	@media #{$medium-up} {
		padding-left:25px;
		font-size:rem-calc(16);
		line-height:rem-calc(30);
	}
}
.autocomplete-suggestion div{
	background-size:16px;
	padding: 9px 4px 9px 30px;
	@media #{$medium-up} {
		padding: 6px 5px 6px 34px;
	}
}
.autocomplete-group{
padding: 4px 10px 4px 10px;
font-size:rem-calc(16);
text-transform: uppercase;
font-weight: bold;
	@media #{$medium-up} {
		padding: 8px 15px 8px 15px;
		font-size:rem-calc(18);
	}
}
.autocomplete-suggestion div.profesionales,.autocomplete-suggestion div.professionals{
	background: url("/media/js/autocomplete/ico-encuentra-medico.svg") 5px 5px no-repeat;
	background-size:16px;
	@media #{$medium-up} {
		background-position:  5px 8px;
	}
}
.autocomplete-suggestion div.especialidades,.autocomplete-suggestion div.procedimientos,.autocomplete-suggestion div.especialitats,.autocomplete-suggestion div.procediments{
	background: url("/media/js/autocomplete/ico-desplega-especialitat.svg") 5px 7px no-repeat;
	background-size:16px;
	@media #{$medium-up} {
		background-position:  5px 10px;
	}
}
.autocomplete-suggestion span {    font-size: 11px;
    color: #999; padding: 0 4px;@include light;}
.autocomplete-no-suggestion { padding: 2px 5px;}
.autocomplete-selected { background-color: #F0F0F0; }
.autocomplete-suggestions strong {
	//@include bold;
	@include light;
	color: #000;
}
.autocomplete-suggestions span strong {
	@include light;
	color: #999;
}