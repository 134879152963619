.collapsible {
  text-align: left;
  border-top: 1px solid #cfcfcf;
  h3 {
    @include typi('normal');
    display: block;
    width: 100%;
    margin: 0;
    color: $secondary-color;
    button {
      padding: vr(.5) vr(1.5) vr(.5) vr(.5);
      display: block;
      width: 100%;
      text-align: left;
      position: relative;
      border-bottom: 1px solid #cfcfcf;
      background-color: transparent;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 1.4em;
        right: vr(.5);
        @include triangle(vr(.25), $primary-color, top);
      }
      &[aria-expanded="true"] {
        margin-bottom: 0;
        border-bottom: 0;
        &:after {
          top: 1em;
          @include triangle(vr(.25), #bcbcbc, bottom);
        }
      }
    }
  }
  h3 + [aria-hidden] {
    display: none;
  }
  h3 + [aria-hidden="false"] {
    display: block;
    @include typi('small');
    padding: vr(.5) vr(.5) 0;
    border-top: 0;
    border-bottom: 1px solid #cfcfcf;
    position: relative;
  }
}
