@include typi-init;
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
}

// headings
h1,
.t1 {
  @include typi("t1");
  @include book;
  margin-bottom: vr(1.5);
  text-transform: uppercase;
  text-align: center;
  display: block;
  @media #{$medium-up} {
    margin-bottom: vr(2);
  }
  .especialidades.fitxa & {
    @include heavy;
    //color: $tertiary-color;
    color: #95958b;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .blog & {
    color: $primary-color;
    text-transform: none;
    @include heavy;
    span {
      text-transform: uppercase;
    }
  }
}
.blog.sticky-header h1 {
  margin-top: -3rem;
  margin-bottom: 1rem;
}
h1 {
  .actualidad.fitxa & {
    text-transform: none;
    @include bold;
    @include typi("news");
    margin-top: vr(0.5);
    margin-bottom: vr(1);
  }
}
h2,
.t2 {
  @include typi("t2");
  margin-top: vr(1.5);
  margin-bottom: vr(1);
  @include bold;
  &:first-of-type {
    margin-top: 0;
  }
  .col-wrap & {
    text-align: center;
    margin-top: 0;
    @media #{$medium-up} {
      text-align: left;
    }
  }
  .section--contact & {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: vr(0.5);
  }
  .simple-block & {
    margin-top: 0;
    margin-bottom: vr(0.5);
  }
  .section--newsletter & {
    @include book;
    @include typi("bigger");
    margin-top: 0;
  }
  .cita-more-info & {
    text-transform: uppercase;
    text-align: center;
    @include book;
    margin-top: 0;
  }
  .confirmacio & {
    color: #57952f;
    text-transform: uppercase;
    @include book;
    @include typi("t1");
    margin-bottom: vr(0.5);
    /*&:before {
      content: '';
      display: none;
      @include sprite('ico-confirmacio-mobil');
      margin: 0 auto vr(1);
      font-size: 1rem;
      @media #{$medium-up} {
        display: block;
      }
    }*/
  }
  .actualidad.fitxa & {
    text-transform: uppercase;
    @include typi("normal");
    &:before {
      content: "";
      display: block;
      width: vr(5);
      height: 1px;
      background-color: #979797;
      margin: 0 auto;
      margin: 0 auto vr(2);
    }
  }
  &.blog-categ {
    text-transform: uppercase;
    color: #929292;
    @include book;
    font-size: rem-calc(14);
    margin-bottom: vr(2);
    span {
      display: block;
      text-transform: none;
      color: $primary-color;
      font-size: rem-calc(32);
    }
  }
}
h3,
.t3 {
  @include typi("t3");
  @include book;
  margin-top: vr(1.5);
  margin-bottom: vr(1);
  .confirmacio &,
  .cita-more-info & {
    text-transform: uppercase;
    color: $primary-color;
    @include typi("normal");
    border-bottom: 1px solid $primary-color;
    padding-bottom: vr(0.25);
    margin-top: 0;
    margin-bottom: vr(0.5);
    display: inline-block;
    @media #{$medium-up} {
      margin-top: vr(2);
    }
  }
  .cita-more-info & {
    margin-top: vr(1);
  }
}
h4,
.t4 {
  @include typi("t4");
  @include light;
  text-transform: uppercase;
  margin-top: vr(1.5);
  margin-bottom: vr(0.5);
}

// mides textos
.smallest {
  @include typi("smallest");
}
.smaller {
  @include typi("smaller");
}
.small {
  @include typi("small");
}
.normal {
  @include typi("normal");
}
.big {
  @include typi("big");
}
.bigger {
  @include typi("bigger");
}
.biggest {
  @include typi("biggest");
}

p,
ol,
ul,
dl,
img,
figure {
  margin: 0 0 vr(1);
}

// dl
.dl-inline {
  width: 100%;
  overflow: hidden;
  dt {
    // @include semibold;
    float: left;
    clear: left;
    margin-right: vr(0.25);
  }
  dd {
    // @include regular;
    margin: 0;
  }
}
.dl-stacked {
  dt {
    // @include semibold;
    margin-top: vr(0.5);
    &:first-child,
    .responsable & {
      margin-top: 0;
    }
  }
  dd {
    margin-left: 0;
    // @include regular;
  }
  span {
    display: block;
  }
  p,
  ul,
  li {
    margin-bottom: 0;
  }
}
.dl-destacat {
  font-size: 0;
  text-align: center;
  margin-bottom: 0;
  div {
    font-size: 1rem;
    margin-bottom: vr(0.5);
    @media #{$medium-up} {
      display: inline-block;
      border-right: 1px solid #979797;
      padding: 0 vr(2);
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
      @media #{$medium-up} {
        border-right: 0;
      }
    }
  }
  dt {
    @include typi("small");
    // // @include semibold;
    text-transform: uppercase;
    @media #{$medium-up} {
      margin-bottom: vr(0.25);
    }
  }
  dd {
    @include typi("t1");
    // @include light;
    margin-left: 0;
  }
}

// llistes
ol {
  padding-left: 0;
  list-style-position: outside;
  margin-left: vr(1.5);
}
// level 1
main ul {
  list-style: none;
  li {
    padding-left: vr(1);
    position: relative;
    margin-bottom: vr(0.25);
    &:before {
      content: "";
      display: block;
      width: 0.75em;
      height: 0.75em;
      left: 0;
      top: 0.3em;
      position: absolute;
      background-color: $secondary-color;
    }
    // level 2
    ul {
      @include typi("small");
      margin-top: vr(0.5);
      margin-bottom: 0;
      li {
        margin-bottom: vr(0.25);
        padding-left: vr(0.75);
        &:before {
          content: "";
          display: block;
          width: 7px;
          height: 7px;
          left: 0;
          top: 0.4em;
          position: absolute;
          background-color: $secondary-color;
          // border-radius: 50%;
        }
        // level 3
        ul {
          @include typi("smaller");
          li {
            padding-left: vr(0.5);
            &:before {
              width: 5px;
              height: 5px;
              top: 0.4em;
            }
          }
        }
      }
    }
  }
}

// llistat amb klanders circulars
main ul.circles {
  li {
    padding-left: vr(0.75);
    &:before {
      width: 7px;
      height: 7px;
      left: 0;
      top: 0.5em;
      position: absolute;
      background-color: $secondary-color;
      border-radius: 50%;
    }
  }
}
// llistat sense klanders
ul.simple,
.simple-block ul {
  @include undolist;
}
.simple-block a {
  @include link(
    $secondary-color,
    $shadow-color: #fff,
    $underline-color: $secondary-color,
    $underline-color-hover: $secondary-color
  );
}
// llistat stripped
.stripped-list {
  li {
    padding: vr(0.5) vr(1);
    &:nth-child(even) {
      background-color: #eaeaea;
    }
    &:before {
      display: none;
    }
    .col-wrap & {
      @media #{$medium-up} {
        margin: 0 vr(-1);
      }
    }
  }
}
div.equipo {
  background-color: #eaeaea;
  margin: 0 -0.75rem;
  padding: 1em 1.5rem 0.1em;
  margin-bottom: 1rem;

  h3 {
    font-size: rem-calc(16);
    @include bold;
    margin: 0;
  }
  .stripped-list {
    padding-bottom: 0 !important;
  }
}
h3.equipo {
  font-size: rem-calc(16);
  @include bold;
  margin: 0;
}
hr.equipo {
  border-color: $body-font-color;
  margin: 1rem auto;
}
// buttons list
.btns-list {
  padding-bottom: vr(1);
  @media #{$medium-up} {
    padding-bottom: vr(2);
  }
  li {
    padding: vr(0.5) vr(1);
    text-align: center;
    margin-bottom: 0 !important;
    &:before {
      display: none;
    }
    @media #{$medium-up} {
      text-align: left;
    }
  }
  span {
    display: block;
    @media #{$medium-up} {
      display: inline-block;
      vertical-align: middle;
      width: 65%;
    }
  }
  .btn {
    margin-top: vr(0.25);
    margin-bottom: 0;
    @media #{$medium-up} {
      margin-top: 0;
      display: inline-block;
      vertical-align: middle;
      float: right;
    }
  }
}

::selection {
  color: #fff;
  background-color: $primary-color;
}
main a::selection {
  text-shadow: none;
}

strong,
b {
  @include bold;
}
em,
i {
  @include bookitalic;
}
em strong,
em b,
i strong,
i b,
strong em,
strong i,
b strong,
b i {
  @include bolditalic;
}
abbr[title] {
  text-decoration: none;
  border-bottom: 0;
}

hr {
  height: 0;
  border: 1px solid $light;
  border-width: 1px 0 0;
  margin: vr(1.5) auto;
  .blog & {
    margin-top: 0;
    border-color: #979797;
  }
  .blog-sidebar & {
    margin-bottom: vr(0.75);
  }
}

// links underline
a {
  @include link($link-color, $shadow-color: #fff, $underline-color: $link-color, $underline-color-hover: $link-color);
  .main-header & {
    @include undolink-2;
  }
}
.section--cita a:not(.btn) {
  @include link(
    $link-color,
    $shadow-color: #f3f3f2,
    $underline-color: $link-color,
    $underline-color-hover: $link-color
  );
}
.section--cita p.btn a {
  @include undolink;
}
.cita-options {
  p.btn a {
    @include typi("smaller");
    padding: vr(0.4) vr(0.8);
    text-transform: none;
  }
}
/*.cita-more-info a:not(.btn) {
  @include link($link-color, $shadow-color: #fff, $underline-color: $link-color, $underline-color-hover: $link-color);
}*/
.lt-ie9 a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

// focus styles
:focus {
  outline: 2px solid $focus;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
  //outline: thin dotted;
  //outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -1px;
}

.dl-link {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: baseline;
    margin-right: 8px;
  }
}
.lead {
  @include typi("big");
  margin-bottom: vr(2);
  &:before,
  &:after {
    content: "";
    display: block;
    width: vr(5);
    height: 1px;
    background-color: #979797;
    margin: 0 auto;
  }
  &:before {
    margin-bottom: vr(1);
  }
  &:after {
    margin-top: vr(1);
  }
}

// figure
figure {
  text-align: center;
  .quienes-somos & {
    text-align: left;
  }
  img {
    margin-bottom: 0;
  }
}
.figure-bg {
  @media #{$medium-up} {
    margin: vr(3) 0;
    background-color: $tertiary-color;
  }
}
.figure-wrapper {
  @media #{$medium-up} {
    background-color: #fff;
  }
}
figcaption {
  @include typi("small");
  @include light;
  text-align: left;
  padding: vr(0.5) 0;
  .fitxa & {
    text-align: center;
  }
  &.narrow {
    @media #{$medium-up} {
      width: 50%;
    }
  }
  em {
    @include lightitalic;
  }
}

// paràgraf destacat
.dest {
  @include typi("big");
  @include light;
  margin-top: vr(2);
  margin-bottom: vr(2);
  text-align: center;
  &:before,
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    height: 1px;
    width: vr(5);
    background-color: $secondary-color;
  }
  &:before {
    margin-bottom: vr(1);
  }
  &:after {
    margin-top: vr(1);
  }
}
.dest-2 {
  text-transform: uppercase;
  padding: vr(1) 0;
  border: 1px solid $tertiary-color;
  border-width: 1px 0;
  color: $primary-color;
}
.dest-3 {
  padding: vr(1);
  background-color: #f7f7f7;
  border-width: 1px 0;
  @include regular;
  strong {
    font-size: rem-calc(22);
    line-height: rem-calc(38);
  }
}

// baixar arxiu destacat
.dl {
  background-color: #f4f4f4;
  padding: vr(0.75) vr(0.5);
  position: relative;
  border-bottom: 1px solid #979797;
  padding-left: rem-calc(60);
  &:before {
    content: "";
    position: absolute;
    margin-right: vr(0.5);
    // // @include sprite(icon-vincle-destacat);
    left: 15px;
    top: 15px;
  }
  a {
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 0 vr(0.75);
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// descarregar arxiu
.arxiu {
  display: inline-block;
  @include typi("small");
  // @include bold;
  margin-bottom: vr(0.75);
  border: 0;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: "";
    vertical-align: middle;
    margin-right: 8px;
  }
  &--pdf {
    &:before {
      // // @include sprite(icon-bases-pdf);
    }
  }
}

// llistat amb columnes
.cols-list {
  list-style: none;
  @media only screen and (min-width: 30em) {
    column-count: 2;
  }
}

// llistat de dades
.dades-list {
  @include typi("small");
}
.icon {
  padding-left: vr(1.25);
  margin-bottom: vr(0.5);
  // @include semibold;
  .rel-links & {
    // @include medium;
    margin-bottom: vr(0.75);
  }
  &:before {
    font-size: 16px;
    top: 1px;
  }
  &.icon--loc {
    // @include medium;
    &:before {
      // @include sprite('ico-location');
    }
  }
  &.icon--tel {
    &:before {
      // @include sprite('ico-phone');
    }
  }
  &.icon--web {
    &:before {
      // @include sprite('ico-web');
    }
  }
  &.icon--email {
    &:before {
      // @include sprite('ico-mail');
    }
  }
  &.icon--ruta {
    &:before {
      // @include sprite('ico-anar');
    }
  }
  &.icon--link {
    padding-left: vr(1.5);
    &:before {
      // @include sprite('ico-link');
      top: -2px;
    }
  }
  &.icon--adjunt {
    padding-left: vr(1.5);
    &:before {
      // @include sprite('ico-adjunt');
      top: -5px;
      left: 4px;
    }
  }
}

// llistat simple, sense klander
.simple-list {
  margin-left: 0;
  li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}

// llistat destacat
.dest-list {
  @include regular;
  li {
    &:before {
      @include sprite("ico-llista");
      vertical-align: middle;
      left: 0;
      top: 0.45em;
      position: absolute;
      background-color: transparent;
    }
  }
}

// llistat amb filets
.bordered {
  margin-bottom: vr(2);
  li {
    border-bottom: 1px solid $light;
    margin-bottom: vr(0.5);
    padding-bottom: vr(0.5);
    // padding-left: 0;
    // &:before {
    //   display: none;
    // }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  a {
    &:hover {
      border-bottom: 1px solid;
    }
  }
}
.bordered-2 {
  @include typi("big");
  li {
    border-bottom: 1px solid $light;
    margin-bottom: vr(0.5);
    padding-bottom: vr(0.5);
    padding-left: 0;
    em {
      @include typi("small");
      font-style: normal;
    }
    &:before {
      display: none;
      padding-left: 0;
    }
  }
}

// llistat enllaços
.rel-links {
  margin-bottom: vr(1.5);
  border-bottom: 2px solid $primary-color;
  .aside & {
    margin-bottom: vr(1);
  }
}

// llista horaris
.horari {
  @extend .bordered;
  li {
    // background-color: aqua;
    padding-left: 0;
    &:before {
      background-color: transparent;
      display: inline-block;
      position: static;
      vertical-align: middle;
      margin-right: vr(0.5);
    }
    &:nth-child(1) {
      &:before {
        @include sprite("ico-horari-1");
      }
    }
    &:nth-child(2) {
      &:before {
        @include sprite("ico-horari-2");
      }
    }
    &:nth-child(3) {
      &:before {
        @include sprite("ico-horari-3");
      }
    }
  }
}

// llistat transports
.transport {
  @extend .bordered;
  @include undolist;
  a {
    @include undolink;
    &:hover {
      // text-decoration: none;
      border: 0;
    }
  }
  img {
    vertical-align: middle;
    display: inline-block;
    margin-right: vr(0.5);
    margin-bottom: 0;
  }
}

// llista horitzontal
.inline-list {
  // text-align: center;
  font-size: 0;
  li {
    font-size: 1rem;
    display: inline-block;
    margin: 0 vr(0.25) 0;
    vertical-align: middle;
    padding-left: 0;
    &:before {
      display: none;
    }
  }
  &--img {
    li {
      margin: 0 vr(0.5) 0;
      &.first {
        margin-left: 0;
      }
      &.last {
        margin-right: 0;
      }
    }
    margin-bottom: 0;
    a {
      @include undolink;
      border: 0;
      opacity: 1;
      transition: opacity 0.2s;
      &:hover {
        border: 0 !important;
        opacity: 0.8;
      }
    }
    img {
      margin-bottom: 0;
    }
  }
  &--share {
    @media #{$medium-up} {
      li {
        margin-right: vr(0.4);
      }
    }
    @media #{$large-up} {
      li {
        margin-right: vr(0.5);
      }
    }
  }
  &--logos {
    margin-bottom: vr(1);
    @media #{$large-up} {
      margin-bottom: 0;
    }
    li {
      // display: block;
      margin: vr(1);
      @media #{$medium-up} {
        display: inline-block;
        margin: vr(0.5) vr(1);
      }
      .home & {
        @media #{$large-up} {
          margin: vr(0.75) 1.1rem;
        }
      }
    }
    img {
      margin-bottom: 0;
    }
  }
  &--mutuas {
    margin-bottom: vr(1);
    .info-block & {
      li {
        margin-left: 0;
        margin-right: vr(2);
      }
    }
  }
  &--nav {
    @include typi("big");
    margin-bottom: vr(2);
    li {
      font-size: inherit;
    }
    a {
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  &--left {
    text-align: left;
    li {
      margin: 0 vr(1.5) 0 0;
    }
  }
}

// table
table {
  @include typi("small");
  margin-bottom: vr(1.5);
  text-align: left;
  width: 100%;
  background-color: #fff;
  th {
    // @include semibold;
  }
  th,
  td {
    vertical-align: top;
  }
  thead {
    th {
      // @include medium;
      padding: vr(0.5);
      color: #fff;
      background-color: $tertiary-color;
      @media #{$large-up} {
        padding: vr(0.5);
      }
    }
  }
  tbody {
    th,
    td {
      padding: vr(0.5);
    }
    tr:nth-child(even) {
      td,
      th {
        background-color: #efeeee;
      }
    }
  }
}

// block-grid
[class*="block-grid"] > li {
  &:before {
    display: none;
  }
}

// intro text
.intro-text {
  color: #fff;
  @include typi("bigger");
  text-align: center;
  margin: vr(0.5) vr(1) vr(1.5) vr(1);
  @media #{$medium-up} {
    margin-bottom: vr(3);
  }
  @media #{$large-up} {
    margin-top: vr(1);
    margin-bottom: vr(1);
  }
  @media only screen and (min-width: 70em) {
    margin-top: vr(2);
    margin-bottom: vr(2);
  }
}

// text més gran
// .big-txt,
.big-txt p {
  @include typi("big");
}

p.borders {
  @include regular;
  border: 1px solid #979797;
  border-width: 1px 0;
  padding: vr(0.5) 0;
}
div.borders {
  @include regular;
  border: 1px solid #979797;
  border-width: 1px 0;
  padding: vr(0.5) 0;
  margin-bottom: vr(1);
  p {
    margin-bottom: vr(0.25);
    line-height: 1.3;
  }
}
.pullout {
  color: $primary-color;
  @include bold;
  @include typi("big");
  width: vr(10);
  &--right {
    @media #{$medium-up} {
      margin-right: vr(-5);
      margin-left: vr(1.5);
      float: right;
    }
  }
  &--left {
    @media #{$medium-up} {
      margin-left: vr(-5);
      margin-right: vr(1.5);
      float: left;
    }
  }
}

.result {
  // background-color: $focus;
  @include bold;
}
.confirmacio {
  .intro p {
    max-width: 600px;
    margin: vr(2) auto;
  }
}

@media #{$medium-up} {
  .widescreen {
    .isDesktop {
      .t1,
      h1 {
        margin-bottom: vr(1.5);
        font-size: rem-calc(36);
      }
      .heading h1 {
        margin-bottom: 0;
      }
    }
  }
}

::selection {
  background: #9ecaff;
  color: black;
}
