.unidades {
	.esp__role {
		color: $primary-color;
		text-align: center;
		font-size: rem_calc(24px);
		margin-bottom: 0;
	}
	.text-2-col {
		@media #{$medium-up} {
			column-count: 2;
			column-gap: 4.5rem;
		}
	}
	.txtequipmedic {
		ul {
			li {
				padding-left: 0;
				&:before {
					content: none;
				}
			}
		}
	}
}
.proves-diagnostiques {
	/*padding: $spacing * 3;*/
	padding: vr(1) vr(0);
	.collapsible {
		@media #{$medium-up} {
			padding: vr(0.5) vr(3);
		}
		border: 0;
		button {
			padding: vr(1) vr(2);
			font-size: rem-calc(18);
			border: 0;
		}
		h3 {
			color: black;
			font-weight: bold;
			& + [aria-hidden="false"] {
				border: 0;
				margin: 0;
				padding: 0;
			}
			button {
				&:after {
					top: 2em;
					right: vr(1.5);
				}
				&[aria-expanded="true"] {
					&:after {
						top: 1.6em;
					}
				}
			}
		}
		li {
			border: 1px $primary-color solid;
			margin-bottom: vr(0.5);
			padding: vr(0);
			&:before {
				display: none;
			}
		}
		p {
			font-size: rem-calc(16);
			margin-bottom: vr(0.5);
		}
		div:first-child {
			padding: vr(1) vr(2);
			margin: 0;
		}
		hr {
			margin: vr(0.5) auto;
		}
	}
}

.new {
	margin-top: vr(4);
	h2 {
		font-size: rem-calc(14);
		text-transform: uppercase;
	}
	time {
		font-size: rem-calc(14);
		display: inline;
	}
	h3 {
		a {
			color: black;
			border: 0;
			text-shadow: none;
			box-shadow: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.category {
		color: $primary-color;
	}
}
.llistat-equip {
	ul {
		list-style: none;
		li {
			padding-left: 0;
			&:before {
				display: none;
			}
		}
	}
}
