.menu-lang {
	@media #{$medium-up} {
		/* text-align: right; */
		margin-bottom: 0;
		margin-right: rem-calc(2);
		margin-right: rem-calc(14);
		border-left: $column-gutter * 0.17 solid #fff;
		display: inline-block;
		text-transform: uppercase;
		vertical-align: middle;
    	position: absolute;
    	top: 0px;
    	right: 70px;
    	height: 100%;
		li {
			@include typi('smaller');
			display: inline-block;
			margin: 0 0 0 vr(.5);
			line-height: vr(1.5);
		}
		a {
			color: #fff;
		}
		abbr {
			border: 0;
			text-decoration: none;
		}
	}
}
.menu-lang-mobile {
	li {
		&:after {
			content: '';
			display: inline-block;
			width: 1px;
			height: vr(.75);
			background-color: #fff;
			vertical-align: middle;
			margin-left: .375rem;
			margin-top: -3px;
		}
		&.last {
			&:after {
				display: none;
			}
		}
	}
	a {
		border-bottom: 0;
		color: #fff;
	}
}