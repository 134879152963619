.landing-urgencies {
    font-family: Montserrat, $font-family-sans-serif;
    font-weight: 300;
    strong {
        font-family: Montserrat, $font-family-sans-serif;
        font-weight: 600;
    }
    .main-header {
        text-align: left;
        background: url("img/landing-urgencies/foto-urgencies-cap.jpg") no-repeat;
        background-size: cover;
        background-attachment: fixed;
        height: 1115px;
        position: relative;
        margin-bottom: 0 !important;
        padding-top: 0;
        top: 0;
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 780px;
            background: linear-gradient(0deg, transparent 0, #fff 100%);
        }
        > .row:first-of-type {
            margin-top: 2.5rem;
            margin-bottom: 3rem;
            @media #{$medium-up} {
                margin-top: 5.25rem;
            }
        }
        a {
            border-bottom: none;
        }
        a:hover,
        a:focus {
            border-bottom: none;
        }
        .tools {
            display: flex;
            gap: 3rem;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
            @media #{$medium-up} {
                justify-content: flex-end;
                margin-top: 0;
            }
            img {
                margin-bottom: 0;
            }
            ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
                margin-bottom: 0;
            }
            .lang {
                font-size: 0.75rem;
                gap: 0.5rem;
                a {
                    display: block;
                    border: 1px solid transparent;
                    border-radius: 5px;
                    padding: 0 0.5em;
                    &.active {
                        border-color: $primary-color;
                    }
                }
            }
        }
    }
    h1 {
        font-size: 3.5rem !important;
        line-height: 1.1;
        color: #338aff;
        text-transform: none;
        text-align: left;
        font-weight: bold;
        text-wrap: balance;
        font-family: Montserrat, $font-family-sans-serif;
        .no-js & {
            opacity: 1 !important;
        }
        @media #{$medium-up} {
            font-size: 4.375rem !important;
        }
    }
    .intro {
        p {
            font-size: 1.5rem;
            .no-js & {
                opacity: 1 !important;
            }
        }
    }
    .intro-2 {
        p {
            font-size: 1.25rem;
            margin-bottom: 0;
        }
    }
    .fotos {
        max-width: none;
        img {
            width: 100%;
            height: 410px;
            object-fit: cover;
            margin-bottom: 0;
        }
    }
    .servei {
        ul {
            list-style: none;
            a {
                color: inherit;
                @include undolink;
                display: block;
                border-bottom: 1px solid #979797;
                padding: 0.5em 0;
            }
        }
        .croquis-container {
            position: relative;
            margin-bottom: 3rem;
            svg:last-child {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
            }
            + p {
                margin-bottom: 0;
            }
        }
    }
    .destacat {
        background: url("img/landing-urgencies/foto-urgencies-metge-infermera.jpg") no-repeat;
        background-size: cover;
        &__container {
            background-color: rgba(255, 255, 255, 0.8);
            padding: 2rem;
            margin: ($column-gutter / 2) 0;
            @media #{$medium-up} {
                margin: 0;
                padding: 4.5rem;
                padding-right: 0;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -200%;
                    width: 200%;
                    background-color: inherit;
                }
            }
        }
        p {
            color: $primary-color;
            font-size: 1.75rem;
            line-height: 1.4;
            margin-bottom: 0;
        }
    }
    .situacio {
        h2 {
            color: #338aff;
            text-wrap: balance;
        }
        img {
            margin-bottom: 0;
            height: auto;
        }
        a {
            color: #000;
            &:has(img) {
                @include undolink;
            }
        }
        .tel {
            display: inline-block;
            font-size: 2.25rem;
            box-shadow: none;
            &:hover,
            &:focus {
                border-bottom: none;
            }
            &:hover {
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 0.25em;
            }
            span {
                font-weight: 500;
            }
        }
    }
    .main-footer {
        margin-top: 0;
        a {
            color: #fff;
            &:focus {
                outline-color: inherit;
            }
        }
        p {
            font-size: 0.875rem;
            margin-bottom: 2.5rem;
        }
        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            column-gap: 2rem;
            row-gap: 1rem;
            justify-content: center;
            margin-bottom: 0;
            font-size: 0.75rem;
        }
    }
}
.bg-primary {
    color: #fff;
    background-color: $primary-color;
}
