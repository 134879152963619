.main-header {
  margin-bottom: vr(0.5);
  text-align: center;
  .home.js-menu-open & {
    margin-bottom: 0;
    @media #{$large-up} {
      margin-bottom: vr(1);
    }
  }
  .blog & {
    margin-bottom: vr(1.5);
  }
  @media #{$medium-up} {
    margin-bottom: vr(1);
    .blog & {
      margin-bottom: vr(1);
    }
  }

  @media #{$large-up} {
    margin-bottom: vr(1);
    padding-top: vr(1);
    position: relative;
    top: vr(-1);
    &.sticky {
      background-color: #fff;
      background-color: rgba(#fff, 0.95);
      position: fixed;
      width: 100%;
      z-index: 2;
      border-bottom: 1px solid $tertiary-color;
      border-bottom-color: rgba($tertiary-color, 0.25);
      top: 0;
      padding-top: 0;
      transition: top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }

  &__tools {
    display: none;
    @media #{$medium-up} {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      background-color: $primary-color;
      text-align: right;
      margin-bottom: 3px;
    }
    @media #{$large-up} {
      background-color: $tertiary-color;
      top: 0;
      /*.blog & {
        background-color: $primary-color;
      }*/
    }
    .sticky-header & {
      display: none;
    }
  }
  &__wrap {
    @media #{$large-up} {
      background-color: $primary-color;
      border: solid #fff;
      border-width: 0 $column-gutter * 0.17;
      margin-left: -$column-gutter * 0.25;
      margin-right: -$column-gutter * 0.25;
      /*.blog & {
        border-color: $primary-color;
      }*/
    }
  }
}
.phone {
  ul {
    text-align: left;
    margin-bottom: 0;

    li {
      font-size: rem-calc(16);
      margin: 0;
      line-height: vr(1);
      color: #fff;
      padding-left: 0;
      display: inline-block;
      margin-right: rem-calc(12);
      &:last-child {
        margin-right: 0;
      }
      @media #{$medium-up} {
        padding-left: 0;
        font-size: rem-calc(14);
        line-height: vr(1.5);
        margin-right: rem-calc(12);
      }
      @media #{$large-up} {
        margin-right: rem-calc(12);
      }
      @media #{$medium-up} {
        &:before {
          display: inline-block;
          background-color: transparent !important;
          vertical-align: middle;
          margin-right: vr(0.25);
          position: static !important;
          content: "";
          @include sprite("ico-telefon-negatiu");
        }
        &:nth-child(2):before,
        &:nth-child(3):before,
        &:nth-child(4):before {
          content: none;
        }
      }
      span {
        //font-size: 1.1rem;
        letter-spacing: 1px;
        display: block;
        @media only screen and (min-width: 360px) {
          padding-left: 0;
        }
        @media #{$medium-up} {
          padding-left: vr(0.1);
          display: inline;
          //font-size: .9rem;
        }
        @media #{$large-up} {
          //font-size: 1.1rem;
          padding-left: vr(0.2);
        }
      }
    }
  }
  &.mobile {
    padding: vr(1.5) vr(0.5);
    margin-bottom: vr(1.5);
    //margin-top: -15px;
    background-color: darken($primary-color, 10%);

    /*&:after{
		    content: '';
		    display: block;
		    height: 1px;
		    width: 4.5rem;
		    background-color: #fff;
		    background-color: rgba(255,255,255,.5);
		    margin: 1.125rem auto 0 auto;
		}*/

    ul {
      @include block-grid(1);
      li {
        margin-right: 0;
        padding-bottom: vr(0.6);
        a {
          display: block;
          padding-left: 2.5em;
          &:before {
            display: inline-block;
            background-color: transparent !important;
            vertical-align: middle;
            margin-right: vr(0.25);
            position: absolute;
            left: 18px;
            top: 5px;
            content: "";
            @include sprite("ico-telefon-negatiu");
          }
        }
        &:before {
          content: none;
        }
        &:after {
          content: "";
          display: block;
          height: 1px;
          width: 75%;
          width: calc(100% - 77px);
          background-color: #fff;
          background-color: rgba(255, 255, 255, 0.5);
          margin: 0.8rem 0 0 2.5em;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
@media screen and (min-width: 720px){
  .phone{
    position: relative;
    left: 20px;
  }
}
@media screen and (min-width: 720px) and (max-width: 1090px) {
  .phone ul{
    max-width: 75%;
  }

}
@media screen and (max-width: 1090px) {
  .menu-lang li{
    padding-top: 33%;
  }
  .social--header li{
    padding-top: 33%;
  }
}

@media screen and (min-width: 1090px) {
  .phone ul{
    max-width: 100%;
  }
  .main-header__wrap{
    height: vr(1.5);
  }
}
.social--header{
  display: none;
  @media #{$large-up} {
    position: absolute;
    top: 0;
    right: 145px;
    text-align: right;
    margin-bottom: 0;
    border-left: $column-gutter * 0.17 solid #fff;
    display: inline-block;
    text-transform: uppercase;
    vertical-align: middle;
    padding:0 rem-calc(20);
    height: 100%;
    li{
      height: 100%;
      margin:0 rem-calc(10);
      a{
        display: inline-block;
        vertical-align: middle;
        vertical-align: -webkit-baseline-middle;
      }

    }
  }
}
.site-title {
  margin: vr(0.5) auto vr(1);
  font-size: 1rem;
  width: rem-calc(225);
  @media #{$medium-up} {
    margin-top: vr(1);
    margin-bottom: vr(1);
    width: auto;
  }
  @media #{$large-up} {
    margin-top: vr(1.5);
    margin-bottom: vr(2);
    float: left;
    .sticky-header & {
      margin-top: vr(1.6);
      margin-bottom: vr(0.25);
      width: rem-calc(200);
    }
  }
  a {
    text-decoration: none;
    border: 0;
    display: inline-block;
  }
  img {
    margin-bottom: 0;
  }
  &.btns {

    &.mobile {
        display: none;
        width: 100%;
        float: none;
        background-color: #fff;
        padding:1rem 0rem;
        margin-top: -0.25rem;
        margin-bottom: 0;
        li{
            width: 265px;
            a{
                display: block;
            }
        }
        &.home{
            margin-top: -2.25rem;
            margin-bottom: 1.5rem;
        }
    }
    @media screen and (max-width: 1024px) {
        display: none;
        float: right;
        li{
            margin-bottom:20px;
        }
        &.mobile {
            display: block;
        }
    }
  }
}
@media #{$medium-up} {
  .widescreen {
    .isDesktop {
      .site-title {
        margin-top: 72px !important;
        margin-bottom: 36px !important;
        line-height: 1;
        font-size: rem-calc(16);
      }
      &.sticky-header .site-title {
        margin-top: vr(1) !important;
        margin-bottom: vr(0.25) !important;
      }
      .main-header {
        margin-bottom: vr(0.25);
      }
    }
  }
}
.no-js {
  .quick-links {
    display: none;
  }
}
.main-header .btns {
  a {
    color: #fff;
    border-radius: 36px;
    padding: vr(0.5) 16px vr(0.5) 42px;
    text-transform: uppercase;
    line-height: 1;
    background: $primary-color;
    border-bottom: none;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 16px;
      top: 50%;
      margin-top: -10px;
    }
    &:hover {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      //background-color: #4a90e2;
    }
    &:focus {
      outline: 0;
      background-color: #4a90e2;
    }
  }
  a.btn-pedir-cita {
    &:before {
      background: url("img/ico-pedir-cita-boto-header.svg") no-repeat 0 0;
      // .blog & {
      //   filter: invert(100);
      // }
    }
    // .blog & {
    //   color: $blog-link;
    //   background-color: #fff;
    //   border: 1px solid #000;
    // }
  }
  a.btn-eshop {
    //background: #b1b1b1;
    background: #338aff;
    padding-left: 18px;
    /*&:before {
      width: 22px;
      height: 22px;
      margin-top: -11px;
      background: url("img/ico-e-shop.svg") no-repeat 0 0;
    }*/
    &:focus {
      outline: 0;
      background-color: #767676;
    }
  }
  a.btn-video {
    //background: #b1b1b1;
    &:before {
        background: url("img/ico-video-boto-header.svg") no-repeat 0 0;
    }
    font-size: 0.93em;
    background: #41A8C2;
    padding-right: 10px;
    padding-left: 35px;
   // padding-left: 18px;
    /*&:before {
      width: 22px;
      height: 22px;
      margin-top: -11px;
      background: url("img/ico-e-shop.svg") no-repeat 0 0;
    }*/
    &:focus {
      outline: 0;
      background-color: #767676;
    }
  }
  .sticky-header & {
    //padding-right: 120px;
    margin-top: 7px;

    /*a.btn-eshop{
      	display: none!important;
      }*/
    a {
      //top:35px;
      padding: vr(0.1) vr(0.5);
      @include typi("small");
      //right: 12px;
      //position: absolute;
      //z-index:1;
      &:before {
        content: none;
      }
    }
  }
  &.site-title {
    .sticky-header & {
      width: auto;
    }
  }
}
.blog.sticky-header .main-header .btns {
  margin-top: 2.5rem;
}
