.blog-form {
  background-color: #f2f2f2;
  padding: vr(1.25);
  margin-bottom: vr(2);
  legend {
    margin: 0 0 vr(0.5) 0;
    padding: 0;
    font-size: rem-calc(18);
    @include regular;
    text-transform: uppercase;
    color: $body-font-color;
  }
  textarea,
  input[type="text"],
  input[type="email"] {
    border-color: #d2d2d2;
  }
  label {
    text-transform: none;
  }
  button {
    margin-bottom: 0;
  }
}
