.top {
  // display: none;
  transition: transform .2s;
  z-index: 2;
  // text-align: right;
  border: 0;
  width: vr(2.5);
  height: vr(2.5);
  display: block;
  margin: 0 auto vr(1);
  @media #{$large-up} {
    display: none;
    width: auto;
    height: auto;
    position: fixed;
    right: $column-gutter*.5;
    bottom: vr(1);
  }
  &:hover,
  &:focus {
    border: 0 !important;
  }
  img {
    margin-bottom: 0;
    max-width: 63px;
  }
}
