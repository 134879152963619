// home video

.splash {
  position: absolute;
  width: 100%;
  height: 100%;
  /*.overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,.3);
    z-index: 2;
  }*/
  iframe {
    display: none;
    // mostrem el video
    @media only screen and (min-width: em-calc(1024)) and (orientation: landscape) {
      display: block;
    }
  }
  video{
    /*height: 100vh;
    min-height: 100%;
    width: auto;*/
    min-width: 100%;
    min-height: 100vh;
    max-width: none;
  }

  &__button {
    display: none;
    position: absolute;
    left: vr(1);
    bottom: vr(2);
    z-index: 3;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      opacity: .8;
    }
    @media only screen and (min-width: em-calc(1024)) and (orientation: landscape) {
      display: block;
    }
  }
}
.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
}


