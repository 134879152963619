// headings color
.heading {
  color: #fff;
  padding: vr(1) 0;
  margin-bottom: vr(1);
  background-color: $primary-color;
  .contacto & {
    background-color: $contacto;
  }
  .servicios & {
    background-color: $servicios;
  }
  .prueba & {
    background-color: $prueba;
  }
  .operar & {
    background-color: $operar;
  }
  .embarazada & {
    background-color: $embarazada;
  }
  .hospi & {
    background-color: $hospi;
  }
  .visita & {
    background-color: $visita;
  }
  .visitar & {
    background-color: $visitar;
  }
  .tramites & {
    background-color: $tramites;
  }
  .color1 & {
    background-color: $color1;
  }
  .color2 & {
    background-color: $color2;
  }
  .color3 & {
    background-color: $color3;
  }
  .color4 & {
    background-color: $color4;
  }
  .color5 & {
    background-color: $color5;
  }
  .color6 & {
    background-color: $color6;
  }

  @media #{$medium-up} {
    padding: vr(1.75) 0;
    margin-bottom: vr(2.5)
  }
  h1 {
    margin-bottom: 0;
  }
}