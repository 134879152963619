.share {
  margin-top: vr(1);
  .panel--conv & {
    border-top: 1px solid #979797;
    padding-top: vr(1);
    margin-top: vr(1);
  }
  .empleo & {
    border-top: 1px solid #979797;
    padding-top: vr(1);
    margin-top: vr(0);
  }
  p {
    // @include typi('big');
    // @include semibold;
    text-transform: uppercase;
    margin-bottom: vr(.5);
  }
  li {
    margin-top: 0;
  }
  background-color: #f7f7f7;

}
.share-wrapper{
  //margin-top: vr(2);
  padding: vr(1) vr(.25) 0;
  min-height: 75px;
  p{
    text-transform: uppercase;
    @include bold;
    margin-bottom: .25em;
    font-size: rem-calc(12);
  }
  @media #{$medium-up} {
    padding-left: 120px;
    position: relative;
    p{
      position: absolute;
      left:10px;
      top:50%;
      transform: translateY(-50%);

    }
  }
  ul{
    margin: 0;
    @include undolist;
    li{
      margin:0 0 vr(1);
      width: 50%;
      display: inline;
      float:left;
      padding-right: 1rem;
      @media #{$medium-up} {
        width: 33%;
      }
    }
  }
  .button{
    //background-color: $primary-color;
    background-color: #0D7CBF;
    color:#fff;
    border: medium none;
    width:100%;
    font-size: rem-calc(12);
    padding:5px 10px;
    text-shadow: none;
    box-shadow: none;
    @include single-transition(background-color);
    display: inline-block;
    @include regular;
    &:hover{
      background-color: darken(#0D7CBF, 10%);
    }
    &--social{
      //font-size: .7em;
      text-transform: uppercase;

      &:hover{
        background-color: #000;
      }
      position: relative;
      padding-left: 32px;
      svg{
        position: absolute;
        top:50%;
        margin-top: -8px;
        left:10px;
      }
    }
    &--facebook{
      background: #3B5998;
    }
    &--twitter{
      background: #55ACEE;
    }
    &--linkedin{
      background: #0077B5;
    }
    &--whatsapp{
      background: #25D366;
    }

  }
}
