.covid19{
	background-color: #000000; 
	max-width: 100% !important; 
	margin-top: 2.3em; font-size: 1.1em;
	z-index: 999;
}
	.covid19 a{
		color: #FFF;
		border: none;
	}
	.covid19 a:hover::after{
		-webkit-transform: translateX(8px);
		transform: translateX(8px);
	}
	.covid19 a::after{
		content:'';
		background: url('/media/css/img/arrow-circle-right.png') no-repeat 0 0;
		width: 24px;
		height: 24px;
		display: inline-block;
		vertical-align: bottom;
		margin-left: 1em;
		display: inline-block;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-property: transform;
		transition-property: transform;
	}
	.covid19 .black{
		padding: 0.5em; 
		background-color: #000000;
	}
		.sticky .covid19{
			margin-top: 0;
		}
