/* pedir cita */
.cita {
  .section{
    @media #{$medium-up} {
      h3{
        margin-top:0;
      }
      .btn{
          margin: 0;
      }
    }

  }
  #form-pedir-cita{
    margin: 0;
    fieldset{
      margin: 0;
    }
    label,
    .label {
      text-align: center;
    }
    h1{
      margin-bottom: vr(.75);
    }
    legend{
      margin-bottom: vr(1.4);
      color:#000;
    }
    .inner-search{
          position: relative;
          margin:0 0 vr(1.25)!important;
          input[type=search] {
              box-sizing: border-box;
          }
          .columns{
            padding-left: 0;
            padding-right: 0;
          }
          @media #{$medium-up} {
            -webkit-box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16), 0 4px 4px 0 rgba(0,0,0,0.16);
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16), 0 4px 4px 0 rgba(0,0,0,0.16);
          }
          .loading{
              position: absolute;
              top: 15px;
              left: 14px;
              background-color: #fff;
              width: 52%;
              height: 30px;
              text-align: left;
              img{
                margin:0;
              }
          }
          input#medico,select#mutuas{
            margin: 0;
            outline: none;
            min-height: 54px;
          }
          input#medico{
              margin-bottom: vr(1);
              @media #{$medium-up} {
                margin-bottom: 0;
              }
          }
          @media #{$medium-up} {
            select#mutuas{
              border-left: none;
            }
          }
          input#medico:focus,input#medico:active{
            background-color: #fff;
            border-color: $primary-color;
          }

    }

    .options{
      text-align: right;
      margin-top:.45rem;
      li{
        padding-right: 0;
        margin-right: 0;
        margin-left: 1rem;
        a{
          @include typi('smaller');
        }
      }

    }
  }
  .cita-options{
    .btn{
      text-transform: none;
    }
  }
  .cita-especialidades{
    margin-bottom: vr(3);
    h1{
      margin-bottom:0;
    }
    h2{
      margin:vr(1) vr(.25) 0;
      text-align: center;
    }
    h3{
      margin:vr(1) vr(.25) vr(.25);
    }
    ul{
      border-bottom: 1px solid #e8e8e8;
      @media #{$medium-up} {
        margin: 0 .375rem 0;
        padding-bottom: vr(.5);
        li{
          padding-left: 0;
          padding-right: vr(1);
          padding-bottom: vr(.5);
          display: inline-block;
          margin: 0;
          vertical-align: middle;
          &:before{
            content: none;
          }
          a{
            box-shadow: none;
            text-shadow:none;
            border-bottom: solid 2px transparent;
            @include single-transition();

            &:hover{
              border-bottom: solid 2px $primary-color;
            }
          }
          small{
            display: block;
          }
        }
      }
    }
    .btn-back{
      margin-top: vr(3);
    }
  }
  .results-step-2{
    position: relative;
    .loading{
          position: absolute;
          top: -30px;
          left: 24px;
          background-color: #fff;
          width: 100%;
          height: 30px;
          text-align: left;
          img{
            margin:0;
          }
          @media #{$medium-up} {
            left:0;
            top: -15px;
            text-align: center;
          }
      }
      .results--doctors{
        @include undolist;
        li{
          padding-bottom:vr(1);
          @media #{$medium-up} {
            width:50%;
            float:left;
            padding-right:vr(.5);
            min-height: 250px;
          }
          &:last-child{
            padding-bottom: 0;
          }
        }

      }
  }
}

@media #{$medium-up} {
  .widescreen {
    .isDesktop {
      .icon--dr{
        display: none;
      }
      .cita .results-step-2 .results--doctors li {
          width: 33%;
      }
      .dr__name{
        font-size: rem-calc(18);
      }
      .dr__esp{
        font-size: rem-calc(14);
      }
      .dr__title{
        font-size: rem-calc(12);
      }
    }
  }
}