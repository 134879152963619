.main-footer {
  text-align: center;
  margin-top: vr(2);
  @media #{$medium-up} {
    margin-top: vr(3);
  }
  @media #{$large-up} {
    margin-top: vr(4);
  }
  &__menu {
    text-transform: uppercase;
    margin-top: vr(1.5);
    margin-bottom: vr(2);
    li {
      margin-bottom: vr(.5);
    }
  }
  p {
    margin-bottom: vr(2);
  }
  p,li {
    @include typi('small');
  }
  a {
    @include undolink;
    color: $secondary-color;
  }
  &--collapse {
    margin-top: 0;
  }
  /*@media #{$small-only} {
    .inline-list {
      margin-bottom: vr(2);
      li {
        display: block;
        margin-bottom: vr(.5);
      }
    }
  }*/
}
.footer-collapse{
  .main-footer {
    margin-top: 0;
  }
}
.social {
  background-color: $tertiary-color;
  padding: vr(1) 0;
  margin-bottom: vr(2);
  @media #{$medium-up} {
    margin-bottom: vr(4);
  }
  a {
    @include undolink;
    color:#000;
  }
}
.logo-footer {
  @media #{$medium-up} {
    display: inline-block !important;
  }
}
.main-footer__info-m {
  padding: vr(1.5) 0;
  &:before,
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: vr(3);
    margin: 0 auto;
    background-color: $tertiary-color;
  }
  &:before {
    margin-bottom: vr(1.5);
    .home & {
      display: none;
    }
  }
  &:after {
    margin-top: vr(1.5);
  }
  p {
    @include typi('normal');
    margin-bottom: 0;
    &.tel {
      display: inline-block;
      background-color: $primary-color;
      color: #fff;
      padding: vr(.5) vr(1);
      margin: vr(.5) 0;
      @include typi('bigger');
    }
    a[href^=tel] {
      color: #fff;
    }
  }
}

@media #{$medium-up} {
  .widescreen {
    .isDesktop {
      .social {
       margin-bottom: vr(2);
      }
    }
  }
}