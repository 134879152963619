// caixes home
.h-box {
  background-color: #fff;
  padding: vr(1) vr(2);
  text-align: center;
  @media #{$large-up} {
    text-align: left;
    padding: vr(1);
  }
  >*:last-child {
    margin-bottom: 0;
  }
  p {
    @include light;
  }
  &--dest {
    p {
      @media #{$large-up} {
        @include typi('big');
      }
    }
    .btn {
      @media #{$large-up} {
        display: none;
      }
    }
  }
  &--dark {
    margin: vr(.5) 0 0;
    background-color: #000;
    background-color: rgba(#000,.7);
    transition: opacity .2s ease-in;
    .js & {
      // opacity: 0;
    }
    .js-open & {
      opacity: 1;
    }
    p {
      color: #fff;
    }
  }
}