.blog-sidebar {
  h2 {
    text-transform: uppercase;
    font-size: rem-calc(18);
    @include regular;
  }
  .blog-item__title {
    margin-top: vr(0.75) !important;
  }
}
