.news-item {
  margin-top: vr(1);
  .home & {
    margin-top: 0;
  }
  @media #{$medium-up} {
    margin-bottom: vr(1.5);
  }
  figure {
    // margin-bottom: vr(.5);
    margin-top: vr(.5);
    margin-bottom: 0;
  }
  h2,h3 {
    @include typi('t2');
    @include bold;
    margin-top: vr(.5);
    margin-bottom: 0;
    a {
      @include undolink;
      color: $body-font-color;
    }
  }
  p {
    margin: vr(.5) 0 0 0;
  }
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: vr(3);
    background-color: $tertiary-color;
    margin: vr(1) auto 0 auto;
    @media #{$medium-up} {
      display: none;
    }
  }
  &--dest {
    margin-top: 0;
    h2, p {
      color: #fff;
    }
    h2 {
      margin-top: 0;
      @media #{$medium-up} {
        font-size: rem-calc(30);
       }
    }
    p {
      //@include typi('big');
       @media #{$medium-up} {
        font-size: rem-calc(18);
       }
    }
    a {
      color: #fff !important;
    }
    &:after {
      display: none;
    }
  }
  &__wrap {
    padding: vr(1);
    background-color: #000;
    background-color: rgba(#000, .4);
    @media #{$medium-up} {
      padding: vr(2);
    }
  }
  &__content {
    @media #{$medium-up} {
      width: 50%;
    }
  }
  .btn {
    margin: vr(1) 0 0;
    color: $primary-color !important;
  }
}
.date {
  display: block;
  color: $primary-color;
  @include typi('small');
  margin-top: vr(.75);
  .news-item--dest & {
    color: #fff;
    margin-top: 0;
    margin-bottom: vr(.5);
  }
  @media #{$medium-up} {
    margin-top: 0;
  }
}