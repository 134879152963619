.btn, p.btn a{
  @include typi('btn');
  @include undolink;
  display: inline-block;
  text-decoration: none;
  margin-bottom: vr(1);
  padding: vr(.5) vr(2);
  text-transform: uppercase;
  text-align: center;
  background-color: transparent;
  border: 2px solid $tertiary-color;
  position: relative;
  z-index: 1;
  transition: all .2s ease-out;
  .h-box & {
    padding-left: vr(1);
    padding-right: vr(1);
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $tertiary-color;
    transition: width .2s ease-out;
  }
  &:hover,
  &:focus,
  &:active {
    background: none;
    border: 2px solid $tertiary-color;
    box-shadow: 0 0 0 rgba(0,0,0,0);
    text-decoration: none;
    .more-info & {
      background-color: #fff;
    }
    @media only screen and (min-width: 64.0625em) {
      color: #fff;
      &:after {
        width: 100%;
      }
    }
  }

  &--small {
    @include typi('smaller');
    border-width: 1px;
    padding: vr(.25) vr(.75);
    &:hover,
    &:focus,
    &:active {
      border-width: 1px;
    }
  }

  &--large {
    @media #{$large-up} {
      @include typi('big');
    }
  }

  &--imp {
    border-color: $secondary-color;
    background-color: $secondary-color;
    color: #fff;
    &:after {
      background-color: #fff;
    }
    &:hover,
    &:focus,
    &:active {
      color: $secondary-color;
      background-color: #fff;
      border-color: $secondary-color;
    }
    .col-wrap & {
      margin-bottom: 0;
    }
  }

  .section--newsletter & {
    background-color: transparent;
    color: #fff;
    width: 100%;
    &:hover,
    &:focus,
    &:active {
      @media only screen and (min-width: 64.0625em) {
        color: $primary-color;
        &:after {
          background-color: #fff;
        }
      }
    }
  }

  &--news {
    margin-top: vr(1);
    margin-bottom: 0;
    .home & {
      margin-bottom: vr(1);
    }
  }

  // botons obrir/tancar home
  &--icon {
    width: vr(2.5);
    height: vr(2.5);
    padding: 0 !important;
    background-color: #fff;
    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      .news-item--dest & {
        margin-top: 1rem;
      }
    }
    &:after {
      display: none;
    }
    &-open {
      &:before {
        @include sprite('open');
      }
    }
    &-close {
      &:before {
        @include sprite('close');
      }
    }
    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
    }
  }

  // botons home
  &--h {
    background-color: #fff;
    border-color: #fff;
    margin-top: vr(.5);
    &:hover,
    &:focus,
    &:active {
      border-color: #fff;
    }
  }

  // botó cita home
  &--cita {
    background-color: $secondary-color;
    border-color: #fff;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
      border-color: #fff;
    }
  }
}
p.btn{
  border: none;
  padding: 0;
  margin-top: vr(1);
  a{
    margin:0;
    &:hover{
      color:#fff;
    }
  }
  &:hover{
    border: none;
    background-color: transparent;
  }
}