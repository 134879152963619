// mapa google
.map {
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 0;
  }
}

// img dreta/esq
.img-right {
  img {
    @media #{$medium-up} {
      float: right;
      margin-left: vr(1);
    }
  }
}
.img-left {
  img {
    @media #{$medium-up} {
      float: left;
      margin-right: vr(1);
    }
  }
}

.back {
  @include typi('small');
  display: inline-block;
  margin-bottom: vr(1);
  @include undolink;
  @media #{$medium-up} {
    position: absolute;
    right: $column-gutter*.5;
    top: vr(.5);
    margin-bottom: 0;
  }
  &:before {
    content: '\2190';
  }
}

.contact-box {
  border: 2px solid $primary-color;
  margin-bottom: vr(1);
  padding: vr(.75);
  position: relative;
  background-color: #fff;
  @media #{$medium-up} {
    position: absolute;
    top: vr(-7);
    right: $column-gutter*.5;
    width: 15rem;
  }
  >*:last-child {
    margin-bottom: 0;
  }
  h2 {
    margin-top: 0;
    @include typi('normal');
    // @include semibold;
    text-transform: uppercase;
    padding-bottom: 0;
    border: 0;
    letter-spacing: 0;
    margin-bottom: 0;
  }
  p {
    @include typi('small');
    margin-bottom: vr(.5);
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    right: -3px;
    background-color: #fff;
    height: 24px;
    width: 24px;
    // @include sprite('box-corner-taronja');
  }
}

.col-wrap {
  background-color: #f3f3f3;
  padding: vr(1) 0 0;
  @media #{$medium-up} {
    margin-top: vr(-2);
    padding: vr(2) vr(1) 0;
  }
  a{
    text-shadow: none;
    box-shadow: none;
  }
  .wrap-doctors & {
    margin-top: 0;
    margin-bottom: vr(2);
  }
  &.varios {
    padding-bottom: vr(.25);
  }
}

.confirmacio__box {
  margin-bottom: vr(1);
  p {
    margin-bottom: 0;
    &.p1 {
      @include bold;
    }
    &.p2 {
      @include light;
    }
  }
}
.confirmacio__list {
   @include undolist;

  li{
    @media #{$medium-up} {
      padding-right: vr(3);
      display: inline-block;
      margin: 0 .375rem 0;
      vertical-align: middle;
      padding-left: 0;
      &:last-child{
        padding-right: 0;
      }
      div{
        text-align: left;
      }
    }

  }
}

// icons
.icon {
  display: block;
  margin: 0 auto vr(.5);
  &--clock {
    @include sprite('ico-pedir-cita');
  }
  &--dr {
    @include sprite('ico-encuentra-medico');
  }
}

// print
.print {
  display: none;
  color: $tertiary-color;
  background-color: #fff;
  @include regular;
  box-shadow: none !important;
  text-shadow: none !important;
  border: 3px solid #a1a196;
  padding: vr(.5);
  position: absolute;
  right: $column-gutter*.5;
  top: 0;
  transition: background-color .2s ease-in !important;
  &:before {
    content: '';
    display: block;
    margin: 0 auto vr(.25);
    @include sprite('ico-imprimir');
  }
  &:hover {
    background-color: rgba(#fff,.5);
  }
  @media #{$large-up} {
    .js & {
      display: inline-block;
    }
  }
}

// imatge amb text overlay
.img-txt {
  @media #{$medium-up} {
    position: relative;
    margin-bottom: vr(2);
    &--last {
      margin-bottom: 0;
    }
    img {
      margin-bottom: 0;
    }
  }


}
.txt-overlay {
    @media #{$medium-up} {
        padding: vr(2) 0;
    }

  @media #{$large-up} {
    padding: vr(2) 0;
    background-color: #fff;
    background-color: rgba(#fff, .7);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .info-block {
      margin-bottom: 0;
    }
  }
}
.mensaje-ok{
  h2{
    color:#57952f;
    text-transform: uppercase;
  }
}
.mensaje-error{
  h2{

    text-transform: uppercase;
  }
}
.hero{
  h2.t1{
    text-align: left;
    margin: vr(1) 0;
    text-transform: none;
    @media #{$medium-up} {
      margin-top: 0;
      font-size: rem-calc(30);
    }
    span{
      display: block;
      font-size: rem-calc(25);
      @include light;
      text-transform: uppercase;
      margin-top: 5px;
    }
  }
  figure{
    margin: 0;
  }
  h3{
    @media #{$large-up} {
      font-size: rem-calc(20);
    }
  }
  @media #{$large-up} {
    &--header{
      p.big{
        max-width: 600px;
        margin-left:auto;
        margin-right:auto;
        text-align: center;
      }
    }
  }
  .card{
    border-radius: 2px;
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
    padding: vr(1);
    background-color: #fff;
    margin-bottom: vr(1);
    ul{
      li{
        font-size: rem-calc(17);
      }
    }
  }
  .section{
    p:last-child, ul:last-child{
      margin-bottom:0;
    }

    .info-block{
      margin:vr(1) 0;
      @media #{$large-up} {
       margin-bottom:0;
       margin-top:0;
      }

    }
  }
}
ul.mutuas{
  li{
    padding:vr(.5);
  }
}
ul.subtitle{
    li{
      span{
        display: block;
        @include typi(small);
      }
    }
}
@media #{$large-up} {
  .tratamientos.fitxa, .especialidades.fitxa, .medico{
    .main-content{
      .medium-6:not(.end){
        &:first-child{
          padding-right: vr(1.5);
        }
        &:last-child{
          padding-left: vr(1.5);
        }
      }
    }
  }
}
