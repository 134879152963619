.blog-nav {
  background-color: $primary-color;
  padding: vr(1) 0;
  @media screen and (min-width: 30em) {
    padding: 0;
  }
  &__level-1 {
    list-style: none;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0;
    @media screen and (min-width: 30em) {
      position: relative;
    }
    > * + * {
      margin-top: vr(0.5);
      @media screen and (min-width: 30em) {
        margin-top: 0;
      }
    }
    > li {
      font-size: rem-calc(14);
      position: relative;
      @media screen and (min-width: 30em) {
        display: inline-block;
        padding: 0;
        position: static;
      }
      &.has-dropdown:hover, &.has-dropdown.open {
        > a {
          position: relative;
          &:after {
            content: "";
            @include triangle(1rem, $primary-color, top);
            display: block;
            position: absolute;
            left: 50%;
            bottom: rem-calc(-47);
            transform: translateX(-50%);
            z-index: 2;
            @media screen and (min-width: 30em) {
              bottom: rem-calc(-32);
            }
          }
        }
        ul {
          display: block;
          position: relative;
          margin-top: rem-calc(15);
          margin-bottom: 0;
          width: 100%;
          @media screen and (min-width: 30em) {
            left: 0;
            right: 0;
            top: rem-calc(69);
            position: absolute;
            margin-top: 0;
            z-index: 1;
          }
          &:before,
          &:after {
            content: "";
            display: block;
            position: absolute;
            background-color: $primary-color;
            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.4),
              transparent
            );
            background-repeat: no-repeat;
            background-size: 100% 57px;
            top: 0;
            bottom: 0;
          }
          &:before {
            left: -100%;
            right: 100%;
          }
          &:after {
            left: 100%;
            right: -100%;
          }
        }
      }
      &:first-child > a:before {
        display: none;
      }
      > a {
        display: block;
        border-bottom: 0;
        @media screen and (min-width: 30em) {
          position: relative;
          padding: vr(1) vr(0.5);
        }
        @media #{$medium-up} {
          padding-left: vr(2);
          padding-right: vr(2);
        }
        &:hover {
          text-decoration: underline;
        }
        &:before {
          @media screen and (min-width: 30em) {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: vr(1);
            background-color: #fff;
            background-color: rgba(#fff, 0.5);
          }
        }
      }
    }
    a {
      color: #fff;
    }
  }
  &__level-2 {
    display: none;
    list-style: none;
    font-weight: normal;
    background-color: $primary-color;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      transparent
    );
    background-repeat: no-repeat;
    background-size: 100% 57px;
    text-transform: none;
    text-align: left;
    padding: vr(1) 0;
    @media screen and (min-width: 30em) {
      padding: vr(2) 0;
    }
    @media #{$medium-up} {
      column-count: 2;
      column-gap: vr(2);
    }
    @media #{$large-up} {
      column-gap: vr(3);
    }
    li {
      font-size: rem-calc(16);
      -webkit-column-break-inside: avoid;
      -webkit-backface-visibility: hidden;
    }
    a {
      display: block;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      padding: vr(0.5) 0;
    }
  }
}

.blog .anchor_offset:before {
  content: " ";
  display: block;
  height: 160px;
  margin-top: -160px;
  visibility: hidden;
}
