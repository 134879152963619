.info-box {
  border: 1px solid $primary-color;
  padding: vr(1) vr(.5);
  margin-bottom: vr(2);
  @media #{$medium-up} {
    padding: vr(1);
    .main-footer & {
      width: 27rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  p {
    @include typi('small');
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__block {
    margin-bottom: vr(1);
    &:last-child {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
}