html,
body {
  overflow-x: hidden; /*afegit perquè el menú desplegable del blog no faci scroll horitzontal*/
}
@media #{$small-only} {
  .main-content {
    .column,
    .columns {
      padding-left: $mobile-padding;
      padding-right: $mobile-padding;
    }
  }
  .row .row {
    margin-left: -$mobile-padding !important;
    margin-right: -$mobile-padding !important;
  }
}
@media #{$large-up} {
  .sticky-header main {
    padding-top: rem-calc(255);
    padding-top: rem-calc(279);
  }
}
.row.narrow {
  @media #{$small-only} {
    margin-left: -$mobile-padding * 0.5 !important;
    margin-right: -$mobile-padding * 0.5 !important;
  }
  @media #{$medium-up} {
    margin-left: -$mobile-padding * 0.25 !important;
    margin-right: -$mobile-padding * 0.25 !important;
  }
  .column,
  .columns {
    padding-left: $column-gutter * 0.5;
    padding-right: $column-gutter * 0.5;
    @media #{$medium-up} {
      padding-left: $column-gutter * 0.25;
      padding-right: $column-gutter * 0.25;
    }
  }
}
.column,
.columns,
.row {
  &:focus {
    outline: none;
  }
}
