// valor necessari per a helpers/_sprites-svg.scss
$mq-base-font-size: 16px;

// modular scale http://www.modularscale.com/?1&em&1.2&sass&text
$ms-ratio: 1.2;

// typi maps:
// map-name: (
//  breakpoint: (font-size, line-height)
// )
// el font-size està basat en l'escala modular (https://github.com/modularscale/modularscale-sass)
$typi: (

  // base map
  base: (
    null: (16px, 1.5)
    // medium: (17px)
    // large: (21px)
  ),

  // maps per títols
  t0: (
    null: (ms(4), 1.15),
    small: ms(5),
    medium: ms(6)
  ),
  t1: (
    null: (ms(3), 1.2),
    small: ms(4),
    medium: ms(6)
  ),
  t2: (
    null: (ms(1), 1.4),
    // small: (ms(1), 1.4),
    medium: ms(2)
  ),
  t3: (
    null: (ms(1), 1.4),
    small: (ms(2), 1.1)
  ),
  t4: (
    null: (ms(1), 1.4),
    small: (ms(1), 1.2)
  ),

  // mides textos
  smallest: (
    null: (ms(-3), 1.3)
  ),
  smaller: (
    null: (ms(-2), 1.4)
  ),
  small: (
    null: (ms(-1), 1.5)
  ),
  normal: (
    null: (ms(0), 1.4)
  ),
  big: (
    null: ms(1),
    small: (ms(2), 1.4)
  ),
  bigger: (
    null: (ms(1), 1.4),
    small: ms(2),
    medium: ms(3)
  ),
  biggest: (
    null: (ms(8), 1.1)
  ),

  // maps components
  nav: (
    null: (ms(0), 1.4)
  ),
  footer: (
    null: (ms(-1), 1.4),
    small: ms(-1)
  ),
  data: (
    null: ms(1),
    small: (ms(1), 1.4)
  ),
  data-num: (
    null: ms(3),
    small: (ms(6), 1.4),
    medium: (ms(7), 1.1),
    large: ms(8)
  ),
  btn: (
    null: (ms(0), 1.4)
  ),
  home-intro: (
    null: (ms(2), 1.1),
    small: ms(5),
    medium: (ms(7), 1),
    large: ms(8)
  ),
  home-sections: (
    null: (ms(3), 1.1),
    small: ms(4),
    medium: (ms(6), 1)
  ),
  news: (
    null: (ms(3), 1.1),
    small: ms(5)
  )
);
//naste_lightregular naste_bookregular
$font-family-sans-serif: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$body-font-family: naste_lightregular, $font-family-sans-serif;
