.slick-dotted.slick-slider {
	margin-bottom: vr(1);
	@media #{$medium-up} {
		margin-bottom: vr(2);
	}
	@media #{$large-up} {
		margin-bottom: vr(3);
	}
}
.slider {
	text-align: center;
	overflow: hidden;
	background-size: cover;
	background-position: 0 0;
	margin-bottom: vr(2);

	// contenidor imatge
	.slick-slide {
		height: rem-calc(145);
		background-color: #333;
		overflow: hidden;
		@media #{$medium-up} {
			height: rem-calc(350);
		}
	}

	img {
		margin-bottom: 0;
		display: block;
	}

	// fletxes
	.slick-prev,
	.slick-next {
		&:before {
			content: '';
			display: block;
			font-size: 16px;
		}
		&:hover,
		&:focus {
			&:before {
				opacity: .8;
			}
		}
	}
	.slick-prev {
		left: vr(.5);
		z-index: 1;
		.js-menu-open & {
			z-index: 0;
		}
		@media #{$medium-up} {
			left: vr(1);
		}
		&:before {
			// @include sprite('fletxa-esq');
		}
	}
	.slick-next {
		right: vr(.5);
		@media #{$medium-up} {
			right: vr(1);
		}
		&:before {
			// @include sprite('fletxa-dreta');
		}
	}

	// botons navegació
	.slick-dots {
		@include undolist;
		margin-bottom: vr(1.5);
	}
}
