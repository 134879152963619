@media print {
  a[href]:after {
    content: '';
  }
  .main-nav,.steps, .section--questions .actions,.social,.main-footer__menu,.main-footer .inline-list,.main-footer__info-m,.main-footer img,.top{
  	display: none!important;
  }
  .logo-footer{
  	margin-top:vr(1);
  }
  .main-footer{
  	margin-top:0;
  }
  .section {
    	padding: .5rem 0;
	}
  .cita{
  	h1{
  		display: none!important;
  	}
  	.confirmacio__list li {
	    padding-right: 4.5rem;
	    display: inline-block;
	    margin: 0 .375rem 0;
	    vertical-align: middle;
	    padding-left: 0;
	    &:last-child{
	    	padding-right: 0;
	    }
	}

	.confirmacio .intro p{
		margin-top:0;
		margin-bottom: 1rem;

	}
	.dest-2{
		display: none;
	}
  }
}
