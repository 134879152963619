.blog-social {
  margin-top: vr(2);
  > * {
    margin-bottom: 0;
  }
  > * + * {
    margin-top: vr(0.5);
  }
  .time {
    @include book;
    display: block;
    font-size: rem-calc(14);
    color: #929292;
  }
  a {
    color: $blog-link;
    @include undolink-2;
  }
  h2 {
    &:before {
      content: "";
      margin-right: vr(0.5);
      display: inline-block;
      vertical-align: sub;
    }
  }
  &--twitter {
    h2 {
      &:before {
        width: 24px;
        height: 21px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='21' viewBox='0 0 24 21'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M24 2.486a9.33 9.33 0 0 1-2.827.835c1.017-.654 1.795-1.695 2.165-2.935a9.418 9.418 0 0 1-3.13 1.29C19.31.644 18.034 0 16.618 0c-2.722 0-4.925 2.373-4.925 5.299 0 .42.043.82.125 1.211C7.728 6.29 4.1 4.176 1.67.972a5.592 5.592 0 0 0-.667 2.661c0 1.842.869 3.463 2.194 4.415a4.617 4.617 0 0 1-2.232-.664v.069c0 2.568 1.695 4.712 3.95 5.196-.413.122-.849.185-1.3.185a4.48 4.48 0 0 1-.927-.092c.63 2.105 2.448 3.638 4.603 3.682-1.684 1.421-3.811 2.266-6.115 2.266-.398 0-.792-.024-1.176-.073C2.18 20.12 4.767 21 7.546 21c9.057 0 14.01-8.078 14.01-15.086 0-.23-.004-.459-.013-.684A10.423 10.423 0 0 0 24 2.486'/%3E%3C/svg%3E%0A");
      }
    }
  }
  &--linkedin {
    h2 {
      &:before {
        width: 22px;
        height: 22px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M1.254.016C.413.313 0 .81 0 1.507v18.964l.038.347C.363 21.61.858 22 1.535 22h18.969c.137 0 .291-.032.445-.099.699-.275 1.051-.802 1.051-1.568V1.656l-.055-.512C21.626.38 21.142 0 20.504 0H1.535l-.281.016zm1.969 4.912c.308-.825.891-1.249 1.771-1.249l.573.066c.885.287 1.33.847 1.33 1.656l-.038.38c-.308.885-.87 1.325-1.678 1.325h-.34l-.424-.06c-.831-.292-1.244-.837-1.244-1.645v-.077l.05-.396zM14.745 8.25c.457 0 .958.077 1.496.231 1.596.589 2.404 2.084 2.404 4.483v5.445h-3.328v-5.33c0-.258-.026-.566-.076-.918-.259-.87-.76-1.315-1.496-1.315h-.231c-.462 0-.875.226-1.244.666-.308.208-.462.802-.462 1.781v5.116H8.525V8.48h3.283v1.375h.04c.737-1.072 1.704-1.606 2.897-1.606zM6.667 18.409h-3.29V8.48h3.29v9.928z'/%3E%3C/svg%3E%0A");
      }
    }
  }
  + hr {
    margin-top: vr(2);
  }
}
