.basic,.houdiniForm {
  margin-bottom: vr(1);
  fieldset {
    border: 0;
    padding: 0;
    margin: 0 0 vr(.5);
    &:focus {
      outline: none;
    }
  }
  legend {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    color: $primary-color;
    margin-bottom: vr(2);
    width: 100%;
    //text-align: center;
    span {
      border-bottom: 1px solid $primary-color;
      padding-bottom: vr(.25);
    }
  }
  label,
  .label {
    display: block;
    text-align: left;
    margin-bottom: vr(.15);
    text-transform: uppercase;
    // color: $primary-color;

  }

  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="number"],
  input[type="date"],
  select,
  textarea {
    @include book;
    width: 100%;
    padding: rem-calc(15) vr(.5);
    background-color: #fff;
    border: 1px solid $tertiary-color;
    margin-bottom: vr(1.5);
    outline: none;
    &:focus {
      background-color: darken(#f7f7f7,5%);
    }
    &.error {
      border: 1px solid red;
    }
  }
  input[type="file"]{
    margin-bottom: vr(1.5);
  }
  .narrow input {
    @media #{$medium-up} {
      padding-left: vr(.25);
      padding-right: vr(.25);
    }
    @media #{$large-up} {
      padding-left: vr(.5);
      padding-right: vr(.5);
    }
  }
  .select-wrapper {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      @include sprite('fletxa-select');
      top: 9px;
      right: vr(.75);
      font-size: 16px;
      pointer-events: none;
    }
  }
  select {
    background-color: #fff;
    appearance: none;
    border-radius: 0;
    padding: rem-calc(14) rem-calc(70) rem-calc(14) vr(.5);
    position: relative;
    &::-ms-expand {
      display: none;
    }
  }
}
.houdiniForm {
  input{
    outline: none;
  }
  input[type="checkbox"] {
    -webkit-appearance: checkbox;
    appearance: checkbox;
    margin-bottom: vr(1);
    &:focus{
        box-shadow: none!important;
    }
  }
  input[type="radio"] {
    -webkit-appearance: radio;
    appearance: radio;
    &:focus{
        box-shadow: none!important;
    }
  }
  select {
    -webkit-appearance: menulist;
    appearance: menulist;
    height: rem-calc(54);
    //padding: 2rem;
    width: 100%;
    border-radius: 0;
  }
  textarea{
    height: 9rem;
  }
  .button{
    margin: vr(2) 0;
  }
  a{
    text-shadow: none;
    box-shadow: none;
  }
}
/* IE9 hack to hide the arrow */
@media screen and (min-width:0\0) {
  select {
    z-index: 1;
    // padding: .5rem 1.5rem .5rem 1rem;
  }
  .select-wrapper:after {
    z-index: 5;
  }
  .select-wrapper:before {
    position: absolute;
    top: 0;
    right: 4rem;
    bottom: 0;
    z-index: 2;
    content: "";
    display: block;
    width: 1.5rem;
    height: 3.3rem;
    background-color: #fff;
    border-top: 1px solid $tertiary-color;
    border-bottom: 1px solid $tertiary-color;
  }
  .select-wrapper select:hover,
  .select-wrapper select:focus,
  .select-wrapper select:active {
    color: #555;
    background-color: #eee;
  }
  select:focus {
    background-color: #fff !important;
  }
}

// estils per input[type="file"]
.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  display: block;
  cursor: pointer;
  padding: rem-calc(15) vr(.5);
  background-color: #fff;
  border: 1px solid $tertiary-color;
  margin-bottom: vr(1);
  text-transform: none;
}
.upload-icon {
  fill: $primary-color;
}
.no-js .inputfile + label {
    display: none;
}
.inputfile:focus + label {
  background-color: darken(#f7f7f7,5%);
}
.inputfile:focus + label,
.inputfile.has-focus + label {
    // outline: 1px dotted #000;
    // outline: -webkit-focus-ring-color auto 5px;
    outline: 3px solid $focus;
}
.inputfile + label * {
  pointer-events: none;
}

// form cita
/*.cita {
  legend {
    // @include visually-hidden;
  }
  label,
  .label {
    text-align: center;
  }
}*/

// newsletter
.newsletter {
  margin-bottom: 0;
  /*label {
    @include visually-hidden;
  }*/
  input[type="email"] {
    padding: vr(.75) vr(1);
    border: 0;
    margin:0;
  }
  .control{
    color:#fff;
    font-size: rem-calc(14);
    a{
      @include undolink;
      color:#fff;
      border-bottom: solid 1px #fff;
      &:hover{
        border-color: #dfdfdf;
        color:#dfdfdf;
        text-decoration: none;

      }
    }
    input:checked~.control-indicator {
        border: solid 1px #fff;
    }
  }
}

// validació
label.error {
  @include typi('small');
  margin-top: vr(-1.25);
  color: red;
  display: block;
  text-align: left;
  text-transform: none;
  padding-bottom: vr(.25);
  position: absolute;
}
label.field-error{
  input[type="checkbox"]{
    box-shadow: inset 0 0 2px #ca3e3e;
    &:focus{
      box-shadow: inset 0 0 2px #ca3e3e!important;
    }
  }
}

// cercador metges
.search-dr {
  margin-bottom: 0;
  .small-10{
    padding-right: 0;
  }
  .small-2{
    padding-left: 0;
  }
  .columns {
    @media #{$medium-up} {
      padding-right: 0;
    }
    &:before {
      @media #{$medium-up} {
        content: '';
        display: block;
        position: absolute;
        @include sprite('boto-lupa-cercador-desplegat');
        left: vr(1);
        top: 50%;
        transform: translateY(-50%);
        opacity: .4;
      }
    }
  }
  fieldset {
    margin-bottom: 0;
    &:before {
      content: '';
      display: none;
      margin: 0 auto vr(1);
      @include sprite('ico-encuenta-tu-medico-cercador');
      @media #{$medium-up} {
        display: block;
      }
    }
  }
  input {
    box-sizing: border-box;
    margin-bottom: 0 !important;
    border-color: #fff !important;
    appearance: none;
    @media #{$medium-up} {
      padding-left: 55px !important;
    }
  }
  button {
    color: #fff;
    background-color: #000;
    text-align: center;
    width: 100%;
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
    margin-top: 0;
    img {
      margin-bottom: 0;
      width: 33px;
      height: 34px;
    }
  }
}
.radio-group {
  margin-bottom: vr(1.5);
  label{
    margin-top: vr(.5);
    &.error{
      margin-top: vr(-.35);
    }
    &.field-error{
      .control-indicator{
        border: 1px solid red;
      }
    }
  }
}
.checkbox-group {
  margin-bottom: vr(1.5);
  label{
    margin-top: vr(1);
    &.error{
      margin-top: vr(-.35);
    }
    &.field-error{
      .control-indicator{
        border: 1px solid red;
      }
    }
  }
}
p.type-label{
  margin-bottom: vr(.15);
}
.houdiniForm {
  .group {
    margin-bottom: vr(1.5);
    label{
      margin-top: vr(.5);
    }
    select{
      margin:0;
    }
  }
  .protecciodades{
    margin-bottom: vr(1.5);
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered li{
      padding: 0 5px;
      margin:15px 5px 6px;
    &:before{
      content:none;
    }
  }
  .select2-container--default .select2-search--inline .select2-search__field{
    margin:0!important;
    &:focus{
        background-color: transparent;
        box-shadow: none!important;
      }
  }
}
.select2-container.select2-container--default .select2-results__option[aria-selected=true]{
    background-color: #fff!important;
    color:#aaa;
  }
