.blog-newsletter {
  color: #fff;
  background-color: $primary-color;
  padding: vr(1);
  margin-top: vr(2);
  h2 {
    text-align: center;
    font-size: 1rem;
    @include regular;
  }
  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    .control {
        margin-top: 0;
        color: #fff;
        font-size: .7em;
        a {
            color: #fff;
            box-shadow: inset 0 -1px 0 0 #fff;
            text-shadow: none;

        }
    }
  }
  input {
    width: 100%;
    margin-bottom: vr(1);
    color: $body-font-color;
    padding: vr(0.25) vr(0.5);
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #999;
    }
  }
  button {
    margin-bottom: 0;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
