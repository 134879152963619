$body-bg: #fff;
$body-font-color: #2e2e2e;
$primary-color: #134189;
$secondary-color: #ca3e3e;
$tertiary-color: #a1a196;
// $tertiary-color: #747466;
$link-color: $primary-color;
//$focus: #ffbf47;
$focus: $primary-color;
$light: #eeeeec;
// $focus: $secondary-color;

// blog
$blog-link: #e3217c;

// colors apartats informatius
$contacto: #134189;
$servicios: #db5611;
$visita: #a1a196;
$prueba: #e74e61;
$operar: #0ec8cd;
$embarazada: #c378d3;
$hospi: #4a90e2;
$visitar: #8ec281;
$tramites: #709643;
$color1: #9c3ecd;
$color2: #c6923f;
$color3: #949494;
$color4: #cc5ab8;
$color5: #a07958;
$color6: #f5a623;
