.dr {
  @media #{$medium-up} {
    @include cf;
    margin-top: vr(2);
  }
  figure {
    @media #{$medium-up} {
      float: left;
      width: 35%;
      padding-right: $column-gutter*.5;
      margin-bottom: 0;
    }
  }
  &__data {
    @media #{$medium-up} {
      float: right;
      width: 65%;
      padding-left: $column-gutter*.5;
    }
  }
  &__title {
    text-transform: uppercase;
    color: #000;
    border-bottom: 1px solid #000;
    padding-bottom: vr(.25);
    margin-bottom: vr(.5);
  }
  &__name {
    @include typi('big');
    @include bold;
    margin-top: 0;
    margin-bottom: vr(.25);
    a{
      text-shadow: none!important;
      box-shadow: none!important;
    }
  }
  &__esp {
    @include light;
    margin-bottom: vr(.5);
    @media #{$medium-up} {
      margin-bottom: vr(1);
    }
  }
  &__wrap {
    display: block;
  }
  &__cv {
    @media #{$medium-up} {
      border: 0;
      padding: 0;
      text-align: left;
      display: inline-block;
      transition: none;
      &:hover {
        background: none;
        color: $primary-color;
        text-decoration: underline;
        border: 0;
      }
      &:after {
        display: none;
      }
    }
  }
  .btn {
    width: auto;
    margin-bottom: vr(.5);
    @include typi('small');
    padding: vr(.25) vr(.75);

    &--imp {
      @media #{$medium-up} {
        @include typi('smaller');
        border-width: 1px;
        padding: vr(.25) vr(.75);
        &:hover,
        &:focus,
        &:active {
          border-width: 1px;
        }
      }
    }
  }
}

@media #{$medium-up} {
  .widescreen {
    .isDesktop {
      .dr {
        margin-top: vr(1);
      }
    }
  }
}