.dr-view,
.dr {
  @media #{$medium-up} {
    overflow-x: hidden;
  }
  p {
    @include light;
  }
  .small-6{
    padding-left: 0;
    padding-right: 0;
    .dr-view__img{
      padding-right: 1rem;
    }
    .dr-view__dest{
       padding: .5rem 1rem 1rem .5rem;
       font-size: rem-calc(12);
    }
    &.dr-view__content{
      padding-right: 1.5rem;
      .btn{
        padding: vr(.25) vr(.5);
      }
    }
  }
  &__content {
    @media #{$medium-up} {
      background-color: #fff;
      z-index: 1;
      min-height: 365px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: $column-gutter*.5;
        top: 0;
        left: -$column-gutter*.5;
        background-color: #fff;
      }
    }
    @media #{$large-up} {
      min-height: 514px;
    }
  }
  &__role {
    margin-bottom: 0;
    text-transform: uppercase;
    @media #{$medium-up} {
      margin-top: vr(2);
    }
  }
  &__name {
    text-align: left;
    text-transform: none;
    margin-bottom: vr(.25);
    span {
      display: block;
    }
  }
  &__esp {
    font-size: rem-calc(19);
  }
  &__request {
    margin-top:  vr(1);
  }
  &__firstname {
    @include light;
  }
  &__lastname {
    @include regular;
  }
  &__subtitle {
    @include typi('normal');
    @include bold;
    margin-bottom: 0;
  }
  &__img {
    margin: 0;
    @media #{$medium-up} {
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 500%;
        top: 0;
        background-color: $tertiary-color;
      }
      &:before {
        left: -500%;
      }
      &:after {
        right: -500%;
      }
    }
  }
  &__dest {
    @include typi('bigger');
    max-width: 90%;
    @media #{$medium-up} {
      margin-top:vr(1);
    }
  }
}