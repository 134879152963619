// webfonts declarations
// webfonts declarations
/* Generated by Font Squirrel (https://www.fontsquirrel.com) on March 15, 2017 */

@font-face {
  font-family: "naste_lightregular";
  src: url("/media/fonts/naste/naste-light-webfont.eot");
  src: url("/media/fonts/naste/naste-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/naste/naste-light-webfont.woff2") format("woff2"),
    url("/media/fonts/naste/naste-light-webfont.woff") format("woff"),
    url("/media/fonts/naste/naste-light-webfont.ttf") format("truetype"),
    url("/media/fonts/naste/naste-light-webfont.svg#naste_lightregular")
      format("svg");
  font-weight: 300;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "naste_lightitalic";
  src: url("/media/fonts/naste/naste-light_italic-webfont.eot");
  src: url("/media/fonts/naste/naste-light_italic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/naste/naste-light_italic-webfont.woff2") format("woff2"),
    url("/media/fonts/naste/naste-light_italic-webfont.woff") format("woff"),
    url("/media/fonts/naste/naste-light_italic-webfont.ttf") format("truetype"),
    url("/media/fonts/naste/naste-light_italic-webfont.svg#naste_lightitalic")
      format("svg");
  font-weight: 300;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "naste_bookregular";
  src: url("/media/fonts/naste/naste-book-webfont.eot");
  src: url("/media/fonts/naste/naste-book-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/naste/naste-book-webfont.woff2") format("woff2"),
    url("/media/fonts/naste/naste-book-webfont.woff") format("woff"),
    url("/media/fonts/naste/naste-book-webfont.ttf") format("truetype"),
    url("/media/fonts/naste/naste-book-webfont.svg#naste_bookregular")
      format("svg");
  font-weight: 400;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "naste_bookitalic";
  src: url("/media/fonts/naste/naste-book_italic-webfont.eot");
  src: url("/media/fonts/naste/naste-book_italic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/naste/naste-book_italic-webfont.woff2") format("woff2"),
    url("/media/fonts/naste/naste-book_italic-webfont.woff") format("woff"),
    url("/media/fonts/naste/naste-book_italic-webfont.ttf") format("truetype"),
    url("/media/fonts/naste/naste-book_italic-webfont.svg#naste_bookitalic")
      format("svg");
  font-weight: 400;
  font-style: italic;
  // font-display: optional;
}

@font-face {
  font-family: "nasteregular";
  src: url("/media/fonts/naste/naste-regular-webfont.eot");
  src: url("/media/fonts/naste/naste-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/naste/naste-regular-webfont.woff2") format("woff2"),
    url("/media/fonts/naste/naste-regular-webfont.woff") format("woff"),
    url("/media/fonts/naste/naste-regular-webfont.ttf") format("truetype"),
    url("/media/fonts/naste/naste-regular-webfont.svg#nasteregular")
      format("svg");
  font-weight: 500;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "nastebold";
  src: url("/media/fonts/naste/naste-bold-webfont.eot");
  src: url("/media/fonts/naste/naste-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/naste/naste-bold-webfont.woff2") format("woff2"),
    url("/media/fonts/naste/naste-bold-webfont.woff") format("woff"),
    url("/media/fonts/naste/naste-bold-webfont.ttf") format("truetype"),
    url("/media/fonts/naste/naste-bold-webfont.svg#nastebold") format("svg");
  font-weight: 700;
  font-style: normal;
  // font-display: optional;
}

@font-face {
  font-family: "nastebold_italic";
  src: url("/media/fonts/naste/naste-bold_italic-webfont.eot");
  src: url("/media/fonts/naste/naste-bold_italic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/naste/naste-bold_italic-webfont.woff2") format("woff2"),
    url("/media/fonts/naste/naste-bold_italic-webfont.woff") format("woff"),
    url("/media/fonts/naste/naste-bold_italic-webfont.ttf") format("truetype"),
    url("/media/fonts/naste/naste-bold_italic-webfont.svg#nastebold_italic")
      format("svg");
  font-weight: 700;
  font-style: italic;
  // font-display: optional;
}

@font-face {
  font-family: "naste_heavyregular";
  src: url("/media/fonts/naste/naste-heavy-webfont.eot");
  src: url("/media/fonts/naste/naste-heavy-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/media/fonts/naste/naste-heavy-webfont.woff2") format("woff2"),
    url("/media/fonts/naste/naste-heavy-webfont.woff") format("woff"),
    url("/media/fonts/naste/naste-heavy-webfont.ttf") format("truetype"),
    url("/media/fonts/naste/naste-heavy-webfont.svg#naste_heavyregular")
      format("svg");
  font-weight: 800;
  font-style: normal;
  // font-display: optional;
}
