.pagination-centered {
  text-align: center;
  margin-top: 0;
  @include grid-row();
  padding:vr(1) vr(.5);
  border-top: 1px solid #ddd;
  .blog & {
    border-top: 0;
  }
}
ul.pagination {
  margin-left: 0;
  list-style: none;
  font-size: 0;
  @include undolist;
  li {
    @include typi('normal');

    margin-bottom: 0;
    display: none;

    &.arrow{
        display: block;
        &.unavailable{
          display: none;
        }
    }
    @media #{$medium-up} {
      display: inline-block;
      &.arrow{
        display: none;
      }
    }
    &.arrow a {
      background-color: transparent;
      @extend .btn;
      height: auto;
      width: auto;
      font-size:rem-calc(14)!important;
      padding: vr(.2) vr(.5)!important;
      text-transform: none!important;
      &:hover {
        opacity: .8;
      }
      /*&:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
      }*/
    }
    &.prev {
      float: left;
      a {
        &:before {
          // @include sprite('pag-back');
        }
      }
    }
    &.next {
      float: right;
      a {
        &:before {
          // @include sprite('pag-next');
        }
      }
    }
    &.unavailable a {
      cursor: default;
      color: #979797;
      opacity: .5;
      &:hover {
        opacity: .5;
      }
    }
    &.current a {
      background-color: $primary-color;
      color: #fff;
    }
    &.hidden {
      @media #{$small-only} {
        display: none;
      }
    }
  }
  a {
    @include undolink;
    border-bottom: none;
    background-color: #ebebeb;
    width: vr(1.25);
    height: vr(1.25);
    line-height: vr(1.25);
    display: block;
    margin: 0 vr(.25) vr(.5);
    &:hover {
      background-color: $primary-color;
      color: #fff;
      text-decoration: none;
    }
  }
}
