.sub-nav {
  // @include semibold;
  @include undolist;
  @include typi('small');
  text-transform: uppercase;
  text-align: center;
  border: solid $primary-color;
  border-width: 2px 2px 0 2px;
  li {
    margin-bottom: 0;
  }
  a {
    @include undolink;
    color: $body-font-color;
    display: block;
    border-bottom: 2px solid $primary-color;
    padding: vr(.5) vr(1);
    &:hover {
      text-decoration: none;
      background-color: $primary-color;
      color: #000;
    }
  }
}
