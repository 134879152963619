.h-btn {
  @include undolink;
  display: block;
  background-color: #fff;
  padding: vr(1);
  position: relative;
  padding-top: vr(4);
  transition: background-color .2s, top .2s;
  top: 0;
  @media #{$medium-up} {
    height: vr(8);
    margin-bottom: vr(1);
  }
  /*@media #{$large-up} {
    height: vr(10);
  }*/
  h2 {
    text-transform: uppercase;
    @include light;
    font-size: rem-calc(18);
    color: $body-font-color;
    margin-top: 0;
    transition: margin-top .2s ease-in;
    @media #{$large-up} {
        font-size: rem-calc(21);
    }
    @media only screen and (min-width: 80em) {
        font-size: rem-calc(23);
    }
    &:before {
      content: '';
      display: block;
      margin-bottom: vr(.5);
      font-size: 1rem;
      position: absolute;
      left: vr(1);
      top: vr(1);
      transition: transform .4s cubic-bezier(0.455, 0.030, 0.515, 0.955);
    }
  }
  // p {
  //   .js & {
  //     visibility: hidden;
  //   }
  // }
  &:hover {
    text-decoration: none;
    background-color: $primary-color;
    background-color: rgba($primary-color,.8);
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    top: -5px;
    h2 {
      color: #fff;
      margin-top: -5px;
      &:before {
        transform: rotateY(180deg);
      }
    }
    // p {
    //   .js & {
    //     color: #fff;
    //     visibility: visible;
    //   }
    // }
  }
  &--cita {
    h2:before {
      @include sprite('ico-pedir-cita');
    }
    &:hover {
      h2:before {
        @include sprite('ico-pedir-cita-negatiu');
      }
    }
  }
  &--medico {
    h2{
      max-width: 132px;
    }
    h2:before {
      @include sprite('ico-encuentra-medico');
    }
    &:hover {
      h2:before {
        @include sprite('ico-encuentra-medico-negatiu');
      }
    }
  }
  &--donde {
    h2:before {
      @include sprite('ico-donde-estamos');
    }
    &:hover {
      h2:before {
        @include sprite('ico-donde-estamos-negatiu');
      }
    }
  }
  &--presupuesto {

    h2:before {
      @include sprite('ico-calculadora');
    }
    &:hover {
      h2:before {
        @include sprite('ico-calculadora-negatiu');
      }
    }
  }
}
