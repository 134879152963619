// Resets margins and paddings for headers, ul, ol and p
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
blockquote {
  margin: 0;
  padding: 0;
}

// Completely resets form items
// ----------------------------
// Super hard reset that removes all borders
// and radius of all form items.
// ============================
input,
textarea,
button {
  border: 0;
  border-radius: 0;
  // outline: none;
  appearance: none;

  // &:hover,
  // &:active,
  // &:focus {
  //   // outline: none;
  // }
  &:active {
    outline: none;
  }
}
::placeholder {
  color: #999;
}

// Use border-box for everything
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
img,
object,
video {
  max-width: 100%;
}

img { -ms-interpolation-mode: bicubic; }
// Get rid of gap under images by making them display: inline-block; by default
img {
  display: inline-block;
  vertical-align: middle;
}

// Sprites
/* @import 'sprites'; */
/* @include sprites($spritesheet-sprites); */
// Creates all retina sprites
/* @include retina-sprites($retina-groups); */
// SVG sprites
@import 'sprites-svg';

