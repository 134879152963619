.img-top {
  background-size: cover;
  background-color: #efefef;
  position: relative;
  margin-bottom: vr(2);
  .especialidades.fitxa &, .info & {
    background-color: $tertiary-color;
    padding-top: vr(1);
    padding-bottom: vr(1);
    @media #{$medium-up} {
      height: rem-calc(430);
      padding-top: vr(3);
    }
    @media #{$large-up} {
      height: rem-calc(510);
      padding-top: vr(5);
    }
  }
  &.small{
    .especialidades.fitxa & {
     padding-top: vr(1);
      padding-bottom: vr(1);
      height: auto;
    }
  }
  &.small{
    .especialidades.fitxa & {
     padding-top: vr(1);
      padding-bottom: vr(1);
      height: auto;
    }
  }
  .trauma & {
    @media #{$medium-up} {
      background-image: url('img/foto-traumatologia.jpg');
      @media #{$retina} {
        background-image: url('img/foto-traumatologia@2x.jpg');
      }
    }
  }

  // imatge gran portada
  .home & {
    background-color: #efefef;
    .columns {
      position: static;
      @media #{$medium-up} {
        position: relative;
      }
    }
  }
  &--intro {
    height: rem-calc(180);
    background-position: 100% 0;
    // background-attachment: fixed;
    overflow: hidden;
    background-image: url('img/foto-portada-small.jpg');
    margin-bottom: 0;
    @media #{$retina} {
      background-image: url('img/foto-portada-small@2x.jpg');
    }
    @media #{$medium-up} {
      position: relative;
      height: auto;
      background-image: url('img/foto-portada-medium.jpg');
      @media #{$retina} {
        background-image: url('img/foto-portada-medium@2x.jpg');
      }
    }
    @media #{$large-up} {
      background-image: url('img/foto-portada-large.jpg');
      @media #{$retina} {
        background-image: url('img/foto-portada-large@2x.jpg');
      }
    }
  }
  // text intro
  &__text {
    @include typi('home-intro');
    @include bold;
    text-align: left;
    color: $primary-color;
    background-color: rgba(#fff,.6);
    padding: vr(1);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    @media #{$medium-up} {
      margin-bottom: vr(2.5);
      margin-top: vr(2.5);
      background-color: transparent;
      padding: 0;
      text-align: center;
      position: static;
      left: auto;
      top: auto;
      height: auto;
      width: auto;
    }
    @media #{$large-up} {
      margin-top: vr(4);
      margin-bottom: vr(4.5);
      max-width: 453px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.text-box {
  background-color: #fff;
  @include typi('bigger');
  @include light;
  padding: vr(1);
  @media #{$medium-up} {
    background: rgba(255,255,255, .85);
    //padding: 0;
    width: vr(12);
    position: absolute;
    right: $column-gutter*.5;
  }
  @media #{$large-up} {
    width: vr(16);
  }
}
.tratamientos {
  .text-box {
    @media #{$medium-up} {
      position: relative;
    }
  }
}

@media #{$medium-up} {
  .widescreen {
    .isDesktop {
      &.fitxa .img-top{
        height: rem-calc(300);
        padding-top: 2rem;
        &.small{
          height: auto;
        }
      }
      .img-top__text{
        max-width: 800px;
        margin-top: vr(3.25);
        margin-bottom: vr(3.75);
      }
      .text-box {
          font-size: rem-calc(21);
      }
    }
  }
}