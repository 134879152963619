.section {
	padding: vr(1) 0;
	@media #{$medium-up} {
		padding: vr(2) 0;
	}
	@media #{$large-up} {
		padding: vr(4) 0;
	}

	&--cita {
		@media #{$large-up} {
			padding: vr(3) 0;
		}


	}

	&--light-bg {
		background-color: #f3f3f2;
	}
	&--programs {
		@media #{$large-up} {
			padding: vr(2) 0;
			&.section--light-bg{
				margin-top:vr(.8);
				h3:first-child{
					margin-top:0;
					 @include light;
				}
			}
		}
	}
	&--contact {
		.tel {
			@include typi('bigger');
			@include light;
			margin-bottom: vr(.25);
			span {
				@include bold;
			}
		}
		.email {
			@include typi('normal');
			@include regular;
			margin-bottom: 0;
			a {
				@include link($primary-color, $shadow-color: $light, $underline-color: $primary-color, $underline-color-hover: $primary-color);
			}
		}
	}
	&--data {
		text-align: center;
		padding-bottom: 0;
		ul {
			text-transform: uppercase;
			@include typi('data');
		}
		// img {
		// 	display: block;
		// 	margin: 0 auto vr(.5);
		// }
		.svg-anim {
			margin-bottom: vr(.5);
		}
		span {
			display: block;
			//color: #4a90e2;
			color: $primary-color;
			@include bold;
			@include typi('data-num');
			text-transform: none;
		}
		.icon-1 {
			margin-top: 41px;
			@media #{$large-up} {
				margin-top: 15px;
			}
		}
		.icon-3 {
			margin-top: 26px;
			@media #{$large-up} {
				margin-top: 0;
			}
		}
		.icon-4 {
			margin-top: 23px;
			@media #{$large-up} {
				margin-top: 18px;
			}
		}
		.icon-5 {
			.svg-anim {
 				margin-top: vr(1);
 				// /margin-bottom: vr(.5);
			}
		}
		.icon-6 {
			margin-top: 49px;
			@media #{$large-up} {
				margin-top: 37px;
			}
		}
	}

	// seccions especialitats home
	&--esp {
		background-size: cover;
		padding: 0;
		@media only screen and (min-width: 64.0625em) {
			background-attachment: fixed;
		}
		.column,
		.columns {
			@media #{$large-up} {
				padding-left: $column-gutter/4;
				padding-right: $column-gutter/4;
			}
		}
		h2 {
			@include heavy;
			@include typi('home-sections');
			text-align: center;
			text-transform: uppercase;
			margin: vr(2) auto;
			transition: color .2s ease-in;
			max-width:600px;
			@media #{$large-up} {
				margin: vr(4) auto;
			}
		}
		button {
			.no-js & {
				display: none;
			}
		}
	}
	&--neuro {
		background-color: #53534a;
		background-image: url('img/foto-neurocirurgia-medium.jpg');
		@media #{$retina} {
		  background-image: url('img/foto-neurocirurgia-medium@2x.jpg');
		}
		@media #{$large-up} {
			background-image: url('img/foto-neurocirurgia-large.jpg');
			@media #{$retina} {
			  background-image: url('img/foto-neurocirurgia-large@2x.jpg');
			}
		}
		@media #{$xlarge-up} {
			background-image: url('img/foto-neurocirurgia-xlarge.jpg');
		}
		h2 {
			color: #fff;
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
			.ie9 & {
				color: $primary-color;
			}
		}
	}
	&--gine {
		background-color: #53534a;
		background-image: url('img/foto-ginecologia-medium.jpg');
		@media #{$retina} {
		  background-image: url('img/foto-ginecologia-medium@2x.jpg');
		}
		@media #{$large-up} {
			background-image: url('img/foto-ginecologia-large.jpg');
			@media #{$retina} {
			  background-image: url('img/foto-ginecologia-large@2x.jpg');
			}
		}
		@media #{$xlarge-up} {
			background-image: url('img/foto-ginecologia-xlarge.jpg');
		}
		h2 {
			color: #fff;
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
			.ie9 & {
				color: $primary-color;
			}
		}
	}
	&--cirugia {
		background-color: #53534a;
		background-image: url('img/foto-cirurgia-general-medium.jpg');
		@media #{$retina} {
		  background-image: url('img/foto-cirurgia-general-medium@2x.jpg');
		}
		@media #{$large-up} {
			background-image: url('img/foto-cirurgia-general-large.jpg');
			@media #{$retina} {
			  background-image: url('img/foto-cirurgia-general-large@2x.jpg');
			}
		}
		@media #{$xlarge-up} {
			background-image: url('img/foto-cirurgia-general-xlarge.jpg');
		}
		h2 {
			color: #fff;
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
			.ie9 & {
				color: $primary-color;
			}
		}
	}
	&--trauma {
		background-color: #efefef;
		background-image: url('img/foto-traumatologia-medium.jpg');
		@media #{$retina} {
		  background-image: url('img/foto-traumatologia-medium@2x.jpg');
		}
		@media #{$large-up} {
			background-image: url('img/foto-traumatologia-large.jpg');
			@media #{$retina} {
			  background-image: url('img/foto-traumatologia-large@2x.jpg');
			}
		}
		@media #{$xlarge-up} {
			background-image: url('img/foto-traumatologia-xlarge.jpg');
		}
		h2 {
			color: $primary-color;
			//color: #fff;
			//text-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
		}
	}
	&--urologia {
		background-color: #efefef;
		background-image: url('img/foto-urologia-medium.jpg');
		@media #{$retina} {
		  background-image: url('img/foto-urologia-medium@2x.jpg');
		}
		@media #{$large-up} {
			background-image: url('img/foto-urologia-large.jpg');
			@media #{$retina} {
			  background-image: url('img/foto-urologia-large@2x.jpg');
			}
		}
		@media #{$xlarge-up} {
			background-image: url('img/foto-urologia-xlarge.jpg');
		}
		h2 {
			color: $primary-color;

		}
	}

	&--da-vinci {
		background-color: #53534a;
		background-image: url('img/foto-cirugia-min-medium.jpg');
		/*@media #{$retina} {
		  background-image: url('img/foto-urologia-medium@2x.jpg');
		}*/
		@media #{$large-up} {
			background-image: url('img/foto-cirugia-min-large.jpg');
			/*@media #{$retina} {
			  background-image: url('img/foto-urologia-large@2x.jpg');
			}*/
		}
		@media #{$xlarge-up} {
			background-image: url('img/foto-cirugia-min-xlarge.jpg');
		}
		h2 {
			color: #fff;
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
			.ie9 & {
				color: $primary-color;
			}
		}
	}


	&--newsletter {
		text-align: center;
		background-color: $primary-color;
		color: #fff;
		input{
			color: #000;
		}
	}
	&--questions {
		text-align: center;
		background-color: #f3f3f2;
		color: #000;
		padding: vr(1.5) 0;
		h3{
			margin:0;
		}
		a{
			color: #000;
			font-size: rem-calc(22);
			margin-top:vr(.25);
		}
		p.contacte-email{
			font-size: rem-calc(13);
			line-height: 1.3;
			margin-top: .85em;
			a{
				font-size: rem-calc(13);
				border-bottom: solid 1px #999;
			}
		}
		.actions{
			padding: 0 0 0 vr(1.5);
			a{
				color: #fff;
				border: none;
				padding: vr(.25);
				background-color: #fff;
				color: $primary-color;
				@include typi(small);
				text-transform: none;
				display: block;
				text-align: center;
				margin:0;
			}
		}
		p{
			margin:0;
		}
		ul{
			margin:0;
			li{
				list-style: none;
				margin-bottom: vr(1);
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		.main-nav & {
			background-color: #f3f3f2;
			padding: vr(.5) vr(1);
			margin: 0 0 vr(1);
			h3{
				font-size: rem-calc(18);
			}
			ul{
				li{
					margin-bottom: vr(.75);
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
		.cita & {
			.actions{
				.cita{
					display: none;
				}
			}
		}
		.especialidades .main-footer & {

			display: none!important;
		}
	}
	&--adr {
		margin-bottom: vr(3);
		padding: vr(2) 0 0;
		a {
			@include link($primary-color, $shadow-color: $light, $underline-color: $primary-color, $underline-color-hover: $primary-color);
		}
	}
	&--search {
		padding: vr(2) 0;
		background-color: $tertiary-color;
	}
}

// desplegables home
.js .more-info {
	height: 0;
	overflow: hidden;
	transition: height .5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	// Eliminem transició perquè fa un efecte extrany en IE
	// IE10+
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	   transition: none;
	}
	// Edge
	@supports (-ms-ime-align: auto) {
		transition: none;
	}
}
.more-info.js-open {
	height: 23rem;
	overflow: auto;
}
.section__wrapper {
	background-color: rgba($primary-color,.1);
	transition: background-color .2s ease-in;
	@media #{$large-up} {
		min-height: 100vh;
		max-height: 1000px;
		/*position: relative;
		&--inner{
			position: absolute;
			top:0;
			width: 100%;



		}*/
	}

}
.js-active {
	background-color: $primary-color;
	background-color: rgba($primary-color,.5);
	h2 {
		color: #fff;
		.ie9 & {
			color: #fff;
		}
	}
}




.js {
	.enter-effect{
		//opacity: 0;
		visibility: hidden;
	}
	.enter-effect--1 {
		visibility: visible;
		opacity: 0;
	    animation: 0.8s ease 0s 1 normal forwards csf-up-fadeIn;
	    will-change: transform, opacity;
	    animation-fill-mode: both;
	    animation-delay: .3s;
	}
	.enter-effect--2 {
		visibility: visible;
		opacity: 0;
	    animation: 0.8s ease 0s 1 normal forwards csf-slideIn;
	    will-change: transform, opacity;
	    animation-fill-mode: both;
	    animation-delay: .3s;
	}
	.enter-effect--3 {
		visibility: visible;
	    animation: 0.8s ease 0s 1 normal forwards csf-down-fadeIn;
	    will-change: transform, opacity;
	    animation-fill-mode: both;
	    animation-delay: 1.5s;
	}
}
@keyframes csf-up-fadeIn{
	0%
	{opacity:0;transform:translateY(50px)
	}
	100%
	{opacity:1;transform:translateY(0px)}
}
@keyframes csf-down-fadeIn{
    0%{
        opacity:0;
        transform:translateY(-50px)
    }
    100%{
        opacity:1;
        transform:translateY(0px)
    }
}
@keyframes csf-down-fadeOut{
    0%{
        opacity:1;
        transform:translateY(0px)
    }
    100%{
        opacity:0;
        transform:translateY(-50px)
    }
}

@keyframes csf-slideIn{
    0%{
        opacity:0;
        transform:translateX(-50px)
    }
    100%{
        opacity:1;
        transform:translateX(0px)
    }
}
@keyframes csf-zoomIn{
    0,20%,40%,60%,80%,100%{
        animation-timing-function:cubic-bezier(0.215, 0.61, 0.355, 1)
    }
    0%{
        opacity:0;
        transform:scale3d(0.3, 0.3, 0.3)
    }
    20%{
        transform:scale3d(1.1, 1.1, 1.1)
    }
    40%{
        transform:scale3d(0.9, 0.9, 0.9)
    }
    60%{
        opacity:1;
        transform:scale3d(1.03, 1.03, 1.03)
    }
    80%{
        transform:scale3d(0.97, 0.97, 0.97)
    }
    100%{
        opacity:1;
        transform:scale3d(1, 1, 1)
    }
}
@media #{$medium-up} {
  .widescreen {
    .isDesktop {
	    &.info ,&.fitxa{
		   .section {
			padding: vr(2) 0;
		   }
	   }
       .section--cita, .section--search {
		padding: vr(2) 0;
  		}
    }
  }
}
