.block-list {
  @include block-grid(1);
  // margin-bottom: vr(1.5) !important;
  margin-left: -$column-gutter/2 !important;
  margin-right: -$column-gutter/2 !important;

  li {
    margin-bottom: 0;
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }

  &--3 {
    @media #{$medium-up} {
      @include block-grid(3);
    }
  }
  &--4 {
    @include block-grid(2);
    @media #{$large-up} {
      @include block-grid(4);
    }
  }
  &--info {
    @include block-grid(1);
    @media only screen and (min-width: 30em) {
      @include block-grid(2);
    }
    @media only screen and (min-width: 48em) {
      @include block-grid(3);
    }
  }
  &--data {
    @include block-grid(1);
    @media only screen and (min-width: 30em) {
      @include block-grid(2);
    }
    @media only screen and (min-width: 48em) {
      @include block-grid(3);
    }
    li {
      padding-bottom: vr(2) !important;
    }
  }
  &--doctors {
    @include block-grid(1);
    @media only screen and (min-width: 30em) {
      @include block-grid(2);
    }
    li {
      padding-bottom: vr(2);
    }
  }
}
.bordered-v{
  @extend .bordered;
  @include undolist;
  @include block-grid(1);
  margin-bottom: vr(2);
  text-align: left;
  @media #{$large-up} {
      @include block-grid(4);
      margin-bottom: vr(2);
      li{
        border: none;
        margin: 0;
        padding: vr(1);
        border-right: 1px solid #eeeeec;
        &:nth-child(4n){
          border: none;
        }
      }
  }
}
.numbers{
  li{
    counter-increment: step-counter;
    position: relative;
    list-style: none;
    padding-left: rem-calc(35);
    &:before {
      content: counter(step-counter);
      position: absolute;
      left:0;
      top:0;
      width: rem-calc(25);
      height: rem-calc(25);
      line-height: rem-calc(25);
      border:solid 1px #4a90e2;
      color:#4a90e2;
      text-align: center;
      border-radius: 100%;
      background-color:transparent;

    }
  }
  @media #{$medium-up} {
    @include block-grid(3);
    li{
      padding-left: rem-calc(68);
      &:before{
        width: rem-calc(50);
        height: rem-calc(50);
        line-height: rem-calc(50);
        font-size:rem-calc(30);
        left: 10px;
      }
    }
  }
}
.list--dest{
  @include undolist;
  @include block-grid(2);
  a {
    @include undolink;
    &:hover {
      // text-decoration: none;
      border: 0;
    }
  }
  li{
    strong{
      display: block;
      @include typi('bigger');
      @include light;
    }
  }
  @media #{$medium-up} {
    @include block-grid(2);
  }
}