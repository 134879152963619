.down {
  @include typi('small');
  // @include bold;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: underline;
  margin-bottom: vr(1);  
  text-align: center;
  text-decoration: none;
  border: 0 !important;
  transition: opacity .1s;
  box-shadow: none;
  img {
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: none;
    border: 0;
  }
  .js-menu-open & {
    display: none;
  }
  @media #{$medium-up} {
    position: absolute;
    margin-bottom: 0;
    left: 50%; 
    bottom: vr(3);
    margin-left: -20px;
    &:hover {
      opacity: .8;
    }
  }
  @media #{$large-up} {
    bottom: vr(1.5);
  }
}
