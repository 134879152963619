.steps {
  background-color: $primary-color;
  @include typi('smaller');
  @media #{$medium-up} {
    @include typi('normal');
  }
  &--completed {
    background-color: #0F2E5E;
  }
  &__list {
    margin: 0;
    list-style: none;
  }
  &__item {
    color: #fff;
    position: relative;
    float: left;
    width: 33.333%;
    padding: vr(2.75) vr(.25) vr(1) vr(.5);
    height: vr(6);
    @media #{$medium-up} {
      //height: vr(8);
      //padding: vr(2) vr(2);
      height: vr(5);
      padding: vr(1) vr(2);
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: vr(.75);
      left: vr(.5);
      font-size: 1rem;
      @include sprite('step-next');
      @media #{$medium-up} {
        left: auto;
        top: 50%;
        right: 0;
        transform: translateY(-50%) translateX(50%);
        z-index: 1;
      }
    }
    &.last {
      &:after {
        @media #{$medium-up} {
          display: none;
        }
      }
    }
    &--current {
      &:before {
        content: '';
        display: block;
        @include sprite('step-current');
        position: absolute;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
        @media #{$medium-up} {
          top: -20px;
        }
      }
    }
    &--completed {
      background-color: #0F2E5E;
    }
  }
  &__name {
    display: block;
    margin-bottom: vr(.5);
    width: 100%;
    display: block;
    text-transform: uppercase;
    @media #{$medium-up} {
      border-bottom: 1px solid #fff;
      padding-bottom: vr(.25);
    }
  }
  &__selection {
    display: block;
    @include bold;
  }
  .column {
    padding-left: $column-gutter*.5;
    padding-right: $column-gutter*.5;
  }
  &.mobile{
    color:#fff;
    padding: vr(.5);
    p{
      margin-bottom: vr(.5);
      span{
        display: block;
        &.selection{
          font-size: rem-calc(17);
        }
      }
      &:last-child{
         margin: 0;
      }
    }
  }
}

@media #{$medium-up} {
  .widescreen {
    .isDesktop {
      .steps__item{
        height: auto;
        padding: vr(.5) vr(2);
      }
    }
  }
}