.site-links {
  background-color: $primary-color;
  padding: vr(1) 0 0;
  a {
    @include undolink;
  }

  // menú 1
  &__1 {
    text-transform: uppercase;
    @include undolist;
    margin-bottom: vr(1.5);
    li {
      margin: 0;
    }
    a {
      display: block;
      background-color: #fff;
      color: $body-font-color;
      margin-bottom: vr(.5);
      padding: vr(.75) vr(.5) vr(.75) rem-calc(55);
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: .5885rem;
      }
      &.cita {
        &:before {
          left: rem-calc(14);
          @include sprite('ico-pedir-cita');
        }
      }
      &.medico {
        &:before {
          left: rem-calc(19);
          @include sprite('ico-encuentra-medico');
        }
      }
      &.donde {
        &:before {
          left: rem-calc(20);
          @include sprite('ico-donde-estamos');
        }
      }
      &.presupuesto {
        &:before {
          left: rem-calc(20);
          @include sprite('ico-calculadora');
        }
      }
      &.promociones {
        &:before {
          left: rem-calc(20);
          @include sprite('ico-e-shop-boto-mobil');
          font-size:.8rem;
          margin-left: -2px;
        }
      }
    }
  }

  // menú 2
  &__2 {
    text-transform: uppercase;
    text-align: center;
    @include undolist;
    margin-bottom: vr(1.5);
    li {
      margin: 0;
      &.last a {
        padding-bottom: 0;
        &:after {
          display: none;
        }
      }
    }
    a {
      display: block;
      color: #fff;
      padding: 0 0 vr(.75) 0;
      &:after {
        content: '';
        display: block;
        height: 1px;
        width: vr(3);
        background-color: #fff;
        background-color: rgba(#fff,.5);
        margin: vr(.75) auto 0 auto;
      }
    }
  }
  .btn.mobile{
    color: #fff;
    border-color: $tertiary-color;
    margin-bottom: vr(1.5);
  }
}