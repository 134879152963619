.info-block {
  margin-bottom: vr(2);
  &:last-child{
    margin-bottom:0;
  }
  @media #{$medium-up} {
    .figure-bg & {
      margin-bottom: 0;
      >*:last-child {
        margin-bottom: 0;
      }
    }
    &.a{
      padding-right: vr(1.75);
    }
    &.b{
      padding-left: vr(1.75);
    }
  }
  h2 {
    @include typi('normal');
    margin-top: 0;
    margin-bottom: vr(.5);
    text-transform: uppercase;
    a {
      @include link($link-color: $body-font-color, $shadow-color: #fff, $underline-color: $body-font-color, $underline-color-hover: $body-font-color);
    }
  }
  p+h2{
    margin-top: vr(2);
  }
  h3 {
    @include typi('normal');
    @include regular;
    margin-top: 0;
    padding-bottom: vr(.25);
    border-bottom: 1px solid #979797;
    margin-bottom: vr(.5);
    &.icon-title {
      &:before {
        content: '';
        display: block;
        margin-bottom: vr(.5);
      }
      &--fgc {
        &:before {
          @include sprite('logo-fgc');
        }
      }
      &--tmb {
        &:before {
          @include sprite('logo-tmb');
        }
      }
    }
  }
  &--pad {
    @media #{$medium-up} {
      padding-left: $column-gutter;
      padding-right: $column-gutter;
    }
  }
  span.big{
    display: block;
  }
  &.datos-contacto{
    p.telefono{
      span.big{
        display: inline;
        font-size: rem-calc(32);
      }
      margin: vr(.5) 0;
    }
  }
  &.video{
    margin-bottom: vr(2);
    @media #{$medium-up} {
        margin-bottom: vr(3);
    }
  }
}
