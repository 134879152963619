.main-nav {
  text-align: center;
  clear: both;
  @media #{$large-up} {
    text-align: left;
  }
  .sticky-header & {
    text-align: right;
    position: relative;
    top: -24px;
    @media #{$large-up} {
      margin-left: 150px;
    }
    @media only screen and (min-width: 1100px) {
      margin-left: 200px;
    }
  }
  .blog & {
    @media #{$medium-up} {
      display: none;
    }
  }
}
.menu-button {
  margin-bottom: rem-calc(14);

  &.isMobile & {
    outline: 0;
    box-shadow: none;
  }
  @media #{$large-up} {
    display: none;
  }
}
.isMobile {
  .menu-button {
    outline: 0;
    &:focus {
      box-shadow: none !important;
    }
  }
}
.menu-wrapper {
  background-color: $primary-color;
  margin-left: -$column-gutter * 0.5;
  margin-right: -$column-gutter * 0.5;
  padding: 0 0 vr(1.5);
  .home & {
    padding-top: vr(1.5);
    @media #{$large-up} {
      padding: 0;
    }
  }
  .blog & {
    padding-bottom: vr(1);
    margin-bottom: vr(1);
  }
  @media #{$large-up} {
    background-color: transparent;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}
/*.blog .menu-lang-mobile {
  margin-bottom: 0;
}*/
.site-menu {
  list-style: none;
  margin-bottom: vr(1.5);
  @media #{$medium-up} {
    margin-bottom: 0;
    column-count: 2;
    margin-right: vr(7);
    margin-left: vr(7);
  }
  @media #{$large-up} {
    @include book;
    //@include typi('nav');
    font-size: rem-calc(15);
    column-count: auto;
    text-transform: uppercase;
    margin-right: 0;
    margin-left: 0;
  }
  li {
    &.first {
      a {
        // @media #{$medium-up} {
        //   padding-top: 0;
        // }
        &:before {
          content: "";
          display: block;
          width: vr(2);
          height: 1px;
          background-color: #fff;
          background-color: rgba(#fff, 0.5);
          margin: 0 auto vr(0.5) auto;
          @media #{$medium-up} {
            display: none;
          }
        }
      }
    }
    &.paciente-int {
      display: none;
    }
    &.cita-mobile {
      display: none;
    }
    // &.last {
    //   a {
    //     &:after {
    //       @media #{$medium-up} {
    //        display: none;
    //       }
    //     }

    //   }
    // }
    @media #{$large-up} {
      display: inline-block;
      margin: 0 0.65em;
      .sticky-header & {
        margin: 0 0.5em;
        font-size: 0.85rem;
      }
      &:nth-child(2) {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media only screen and (min-width: 1000px) and (max-width: 1120px) {
      margin: 0 0.1em;
      .sticky-header & {
        margin: 0;
      }
    }
    @media only screen and (min-width: 1120px) {
      //margin: 0 1.25em;
      margin: 0 0.55em;
      .sticky-header & {
        margin: 0 0.4em;
        font-size: 0.8rem;
      }
    }
  }
  a {
    color: #fff;
    padding: vr(0.5) 0 0;
    display: block;
    border: 0;
    @media #{$medium-up} {
      display: inline-block;
    }
    @media #{$large-up} {
      color: #747466;
      padding: 0;
      display: inline;
      border-bottom: 2px solid transparent;
      @include single-transition("border-color");
    }
    &:after {
      content: "";
      display: block;
      width: vr(2);
      height: 1px;
      background-color: #fff;
      background-color: rgba(#fff, 0.5);
      margin: vr(0.5) auto 0 auto;
      @media #{$large-up} {
        display: none;
      }
    }
    &:hover,
    &[aria-current="page"] {
      border-bottom: 0;
      @media #{$large-up} {
        text-decoration: none;
        border-bottom: 2px solid $tertiary-color;
      }
    }
    &[aria-current="page"] {
      @include bold;
      @media #{$large-up} {
        @include book;
      }
    }
  }
}
.home .site-menu li {
  &.cita-mobile {
    display: block;
    @media #{$medium-up} {
      display: none;
    }
  }
}
#menu {
  position: relative;
  /*a.btn-pedir-cita{
    color:#fff;
    border-radius: 36px;
    padding: vr(.5) 16px vr(.5) 42px;
    position: absolute;
    top:-12px;
    right:0;
    text-transform: uppercase;
    line-height: 1;
    background:$primary-color;
    //transition: all .3s;
    border-bottom: none;
    &:before{
      content:'';
      display: block;
      width:20px;
      height: 20px;
      position: absolute;
      left:16px;
      top:50%;
      margin-top:-10px;
      background: url('img/ico-pedir-cita-boto-header.svg') no-repeat 0 0;

    }
    &:hover{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
      //background-color: #4a90e2;

    }
  }*/
  .sticky-header & {
    //padding-right: 120px;
    margin-top: 2px;
    /*a.btn-pedir-cita{
        top:-2px;
        padding: vr(.1) vr(.5);
        @include typi('small');
        &:before{
          content: none;
        }
      }*/
    .site-menu li.actualidad {
      display: none;
    }
  }
}
.ca.sticky-header #menu {
  padding-right: 150px;
}
@media #{$medium-up} {
  .widescreen {
    .isDesktop {
      .site-menu a,
      #menu a.btn-pedir-cita {
        font-size: rem-calc(14);
      }
      &.sticky-header {
        .site-menu a,
        #menu a.btn-pedir-cita {
          font-size: rem-calc(12.8);
        }
      }
    }
  }
}
