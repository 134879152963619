.blog-comments {
  background-color: #f2f2f2;
  padding: vr(1.25);
  margin-bottom: vr(2);
  summary {
    list-style: none;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  h3 {
    margin: 0;
    font-size: rem-calc(18);
    @include regular;
    text-transform: uppercase;
    cursor: pointer;
    &:after {
      content: "";
      @include triangle(4px, #979797, top);
      display: inline-block;
      vertical-align: baseline;
      margin-left: vr(0.5);
    }
    &:hover {
      text-decoration: underline;
    }
  }
  ol {
    list-style: none;
    margin: vr(1.25) 0 0 0;
    > * + * {
      margin-top: vr(0.75);
    }
    li {
      padding-bottom: vr(0.75);
      border-bottom: 1px solid #979797;
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
      .responce {
        margin-top: vr(0.75);
        margin-left: vr(2);
      }
    }
  }
  &[open] h3 {
    &:after {
      @include triangle(4px, #979797, bottom);
      display: inline-block;
      position: relative;
      top: -4px;
    }
  }
  &__meta {
    margin-top: vr(0.5);
    @include book;
    font-size: rem-calc(14);
    color: #929292;
    display: flex;
    justify-content: space-between;
    a {
      color: #929292;
      @include undolink-2;
    }
  }
  &__auth {
    &:before {
      content: "";
      margin-right: vr(0.5);
      display: inline-block;
      width: 17px;
      height: 17px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cpath fill='%23DADADA' fill-rule='nonzero' d='M15.3 0H1.7C.765 0 0 .765 0 1.7V17l3.4-3.4h11.9c.935 0 1.7-.765 1.7-1.7V1.7c0-.935-.765-1.7-1.7-1.7z'/%3E%3C/svg%3E%0A");
      vertical-align: bottom;
    }
  }
}
