.link {
  padding: vr(.5) vr(1);
  text-align: left;
  border: 1px solid $light;
  width: 100%;
  padding-left: vr(2);
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: transparent;
    border-radius: 0;
    left: vr(.5);
  }
  // a {
  //   border-bottom: 0;
  //   &:hover {
  //     border-bottom: 1px solid;
  //   }
  // }
  em {
    @include typi('small');
    display: block;
  }
  &--dl {
    &:before {
      top: 15px;
      @include sprite('baixa-arxiu');
    }
  }
}