.blog-item {
  //   border-bottom: 1px solid #979797;
  //   padding-bottom: vr(1);
  //   border: 1px solid;
  margin-bottom: vr(1.5);
  .blog-sidebar & {
    margin-bottom: vr(0.75);
  }
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  > * + * {
    margin-top: vr(0.5);
  }
  a {
    @include undolink;
  }
  &__meta {
    font-size: rem-calc(14);
    time {
      color: #929292;
      padding-right: vr(0.5);
      margin-right: vr(0.5);
      border-right: 1px solid #979797;
      .blog-categ-list & {
        border-right: 0;
        padding-right: 0;
        margin-right: 0;
      }
    }
  }
  &__title {
    margin-top: vr(0.25) !important;
    margin-bottom: 0;
    text-transform: none;
    a {
      color: $body-font-color;
    }
  }
  &__stars {
    margin-bottom: 0;
    margin-right: vr(0.5);
    vertical-align: baseline;
  }
  &__score {
    display: inline-block;
    margin-right: 1em;
    img {
      margin: 0 0 2px 0;
    }
  }

  &__info {
    font-size: rem-calc(14);
    text-align: right;
    a,
    span {
      color: #929292;
      white-space: nowrap;
      &:before {
        content: "";
        margin-right: vr(0.5);
        display: inline-block;
        width: 17px;
        height: 17px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cpath fill='%23DADADA' fill-rule='nonzero' d='M15.3 0H1.7C.765 0 0 .765 0 1.7V17l3.4-3.4h11.9c.935 0 1.7-.765 1.7-1.7V1.7c0-.935-.765-1.7-1.7-1.7z'/%3E%3C/svg%3E%0A");
        vertical-align: bottom;
      }
    }
    .blog-categ-list & {
      margin-top: vr(0.5);
    }
  }
  &--l0 {
    > * + * {
      margin-top: vr(1.5);
    }
    .blog-item__title {
      font-size: rem-calc(30);
      @include light;
      margin-top: vr(1) !important;
    }
  }
  &--l1 {
    .blog-item__title {
      font-size: rem-calc(30);
      @include light;
    }
  }
  &--l2 {
    .blog-item__title {
      font-size: rem-calc(24);
      @include light;
      margin-top: vr(0.25) !important;
    }
  }
  &--l3 {
    .blog-item__title {
      font-size: rem-calc(20);
      @include book;
    }
  }
  &--l4 {
    .blog-item__title {
      font-size: rem-calc(22);
      @include book;
    }
  }
  &--l5 {
    .blog-item__title {
      font-size: rem-calc(16);
      @include book;
    }
  }
  > .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &__pict {
    @include grid-column(12);
    padding-left: 0;
    padding-right: 0;
    @media screen and (min-width: 30em) {
      @include grid-column(6);
      padding-left: 0;
    }
    @media #{$medium-up} {
      @include grid-column(4);
      padding-left: 0;
    }
    img {
      @media screen and (min-width: 30em) {
        margin-bottom: 0;
      }
    }
  }
  &__text {
    @include grid-column(12);
    padding-left: 0;
    padding-right: 0;
    @media screen and (min-width: 30em) {
      @include grid-column(6);
      padding-right: 0;
    }
    @media #{$medium-up} {
      @include grid-column(8);
      padding-right: 0;
    }
  }
  &__auth {
    font-size: rem-calc(14);
    color: $primary-color;
    line-height: 2.5;
    @media #{$large-up} {
      line-height: initial;
    }
    &:before {
      content: "";
      margin-right: vr(0.5);
      display: inline-block;
      width: 11px;
      height: 11px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11'%3E%3Cpath fill='%23000' fill-rule='nonzero' d='M0 8.709V11h2.291L9.05 4.242 6.758 1.951 0 8.709zM10.821 2.47a.609.609 0 0 0 0-.862L9.391.178a.609.609 0 0 0-.861 0L7.412 1.298l2.291 2.291 1.118-1.118z'/%3E%3C/svg%3E%0A");
    }
    > * {
      padding-right: vr(0.5);
      margin-right: vr(0.5);
      border-right: 1px solid #979797;
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }
    }
  }
  &__name {
    @include book;
  }
  &__section {
    @include light;
  }
  &__cita {
    color: #fff;
    border-radius: 36px;
    padding-top: vr(0.25);
    padding-bottom: vr(0.25);
    padding-left: 44px;
    padding-right: 16px !important;
    text-transform: uppercase;
    line-height: 1;
    border-bottom: none;
    position: relative;
    background: #b1b1b1;
    white-space: nowrap;
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 16px;
      top: 50%;
      margin-top: -10px;
      background: url("img/ico-pedir-cita-boto-header.svg") no-repeat 0 0;
    }
    &:hover {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    }
    &:focus {
      outline: 0;
      background-color: #767676;
    }
  }
  &__author {
    border: 1px solid #979797;
    padding: vr(1);
    @media screen and (min-width: 30em) {
      display: flex;
      align-items: flex-start;
    }
    > div:first-child {
      img {
        border-radius: 50%;
      }
    }
    > div:last-child {
      @media screen and (min-width: 30em) {
        flex: 1;
        margin-left: vr(1);
      }
      h2 {
        font-size: rem-calc(18);
        margin-bottom: 0;
      }
      .info {
        color: #2e2e2e;
        padding: vr(0.5) 0;
        border: solid #e4e4e4;
        border-width: 1px 0;
        margin: 0 0 1.5rem;
        > * {
          margin: 0;
        }
        > * + * {
          margin-top: vr(0.5);
        }
      }
      .btns {
        display: flex;
        justify-content: space-between;
      }
      ul {
        @include undolist;
        margin-bottom: 0;
        img {
          margin-bottom: 0;
        }
      }
      .btn {
        margin-bottom: 0;
        color: #fff;
        background-color: $primary-color;
        border-color: $primary-color;
        &:after {
          display: none;
        }
        &:hover,
        &:focus {
          color: $primary-color;
          background-color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}
