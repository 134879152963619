// Search mobile
.s-mobile {
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    background-color: #fff;
  }
  label,
  legend {
    @include visually-hidden;
  }
  input {
    appearance: none;
    padding: vr(.5) 0;
    width: 70%;
  }
  button {
    background-color: transparent;
    width: 15%;
    padding: 0;
    text-align: right;
    img {
      margin: 0;
      width: rem-calc(18);
      height: rem-calc(19);
    }
  }
  .small {
    margin: vr(.5) 0 0 0;
    a {
      color: #fff;
    }
  }
}

// Search desktop
@media #{$medium-up} {
  .js-button {
    padding: 0;
    margin: 0;
    height: vr(1.5);
    width: vr(2.5);
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    border-left: $column-gutter * 0.17 solid #fff;
    position: absolute;
    top: 0;
    right: 10px;
    height: 100%;

    /*.blog & {
      border-color: $primary-color;
    }*/
    img {
      margin: 0 auto;
      display: block;
    }
  }
  .s-desktop {
    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
      background-color: $primary-color;
      .js & {
        border-top: vr(.125) solid #fff;
        width: 30rem;
        position: absolute;
        right: $column-gutter*.5;
        text-align: center;
        padding: vr(1.5);
        z-index: 2;
      }
    }
    legend,
    label {
      @include visually-hidden;
    }
    input {
      appearance: none;
    }
    button {
      display: inline-block;
      vertical-align: middle;
      background-color: transparent;
      padding: 0;
      img {
        margin: 0;
        display: block;
      }
    }
    .no-js & {
      display: none;
      display: inline-block;
      vertical-align: middle;
      margin-right: vr(1);
      input {
        @include typi('small');
        border: 0;
        display: inline-block;
        vertical-align: middle;
        padding: 0 vr(.25);
      }
      a {
        @include typi('smaller');
        color: #fff;
        display: inline-block;
        vertical-align: middle;
        margin-left: vr(.25);
      }
    }
    .js & {
      input {
        border: 0;
        display: inline-block;
        vertical-align: middle;
        padding: 0 vr(.5);
        margin-right: vr(.5);
        line-height: vr(2);
        width: 20rem;
        // IE10+
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
           height: 48px;
        }
      }
      span {
        display: block;
        margin-top: vr(1);
      }
      a {
        @include typi('smaller');
        color: #fff;
        text-transform: uppercase;
        border: 1px solid #fff;
        padding: vr(.5) vr(.75);
        display: inline-block;
        transition: background-color .2s;
        &:hover {
          background-color: #fff;
          color: $primary-color;
        }
      }
    }
  }
}

.js .s-desktop .quick-links{
  text-align: left;
  margin-top: vr(1);
  .header-title{
    color: #fff;
    margin-top:0;
    margin-bottom:vr(.35);
    @include typi(normal);
    padding-left: 10px;
  }
  ul{
    margin-bottom: 0;
    padding-left: 10px;
    li{
      padding-bottom: vr(.1);
      a{
        border: none;
        padding: 0;
        text-transform: none;
        @include typi(small);
        &:hover{
         background-color: transparent;
         text-decoration: underline;
         color:#fff;
        }
      }
    }
  }
}

input::-webkit-input-placeholder {
  color: #757575; /* a11y FTW! */
}
input:-ms-input-placeholder {
  color: #757575; /* a11y FTW! */
}
input::-moz-placeholder {
  color: #757575; /* a11y FTW! */
  opacity: 1; /* https://www.quirksmode.org/blog/archives/2015/12/styling_placeho.html */
}
.cercador{
  article{
    border-bottom: 1px solid #ddd;
    padding-bottom: vr(1);
    margin-bottom: vr(1);
    &:last-child{
      border-bottom:none;
      margin-bottom: 0;
    }

    h2{
      @include light();
      font-size: rem-calc(20);
      @media #{$medium-up} {
        font-size: rem-calc(32);
      }
    }
    p{
      font-size: rem-calc(14);
      @media #{$medium-up} {
        font-size: rem-calc(16);
      }
    }
    .breadcrumbs{
      @include undolist;
      margin:0;
      li{
        display: inline-block;
        padding-right:3px;
        a{
          color: #888;
          @include undolink;
          @include typi(small);
        }
        &:after{
          content:'>';
          padding-left:3px;
        }
      }

    }
  }
}