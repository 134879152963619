.logo-dnv{
    position: absolute;
    top:auto;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 140px;
    @media #{$medium-up} {
        top:0;
    }
    img{
        position: relative;
        margin-top: 0rem;
        margin-bottom: 1rem;
        right: -1.5rem;
        @media #{$medium-up} {
            margin-top: 2rem;
            right: 1.5rem;
        }

    }
}
