.fotos-list {
	text-transform: uppercase;
	@include typi('big');
	@include regular;
	a {
		@include undolink;
		display: block;
		color: #fff;
		background-color: $primary-color;
		padding: vr(.75) vr(1);
		background-repeat: no-repeat;
		background-size: cover;
		background-blend-mode: multiply;
		height: vr(8);
		transition: background-color .2s ease-in;
		overflow: hidden;
		position: relative;
		/*img{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
		}
		div{
			background-color: $primary-color;
			opacity:.9;
			position: absolute;
			transition: opacity .2s ease-in;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 2;

		}*/
		span{
			/*position: absolute;
			top: 0;
			left: 0;
			z-index: 3;
			padding: vr(.75) vr(1);*/
			max-width: 220px;
		}
		@media #{$medium-up} {
			height: vr(9);
		}
		@media #{$large-up} {
			height: vr(11);
		}
		&:hover {
			text-decoration: none;
			animation: text-shadow-drop-center .6s both;
			span {
				top: -6px;
			}
		}
	}
	/*&--contacto a {
		background-color: $contacto;
		background-image: url('img/info-practica-contacto-ubicacion-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-contacto-ubicacion-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-contacto-ubicacion.jpg');
			&:hover {
				background-color: lighten($contacto,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-contacto-ubicacion@2x.jpg');
			}
		}
	}
	&--servicios a {
		background-color: $servicios;
		background-image: url('img/info-practica-servicios-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-servicios-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-servicios.jpg');
			&:hover {
				background-color: lighten($servicios,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-servicios@2x.jpg');
			}
		}
	}
	&--visita-medica a {
		background-color: $visita;
		background-image: url('img/info-practica-concertar-visita-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-concertar-visita-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-concertar-visita.jpg');
			&:hover {
				background-color: lighten($visita,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-concertar-visita@2x.jpg');
			}
		}
	}
	&--prueba a {
		background-color: $prueba;
		background-image: url('img/info-practica-solicitar-prueba-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-solicitar-prueba-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-solicitar-prueba.jpg');
			&:hover {
				background-color: lighten($prueba,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-solicitar-prueba@2x.jpg');
			}
		}
	}
	&--operar a {
		background-color: $operar;
		background-image: url('img/info-practica-operarme-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-operarme-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-operarme.jpg');
			&:hover {
				background-color: lighten($operar,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-operarme@2x.jpg');
			}
		}
	}
	&--embarazada a {
		background-color: $embarazada;
		background-image: url('img/info-practica-estoy-embarazada-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-estoy-embarazada-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-estoy-embarazada.jpg');
			&:hover {
				background-color: lighten($embarazada,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-estoy-embarazada@2x.jpg');
			}
		}
	}
	&--hospi a {
		background-color: $hospi;
		background-image: url('img/info-practica-hospital-dia-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-hospital-dia-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-hospital-dia.jpg');
			&:hover {
				background-color: lighten($hospi,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-hospital-dia@2x.jpg');
			}
		}
	}
	&--visitar-paciente a {
		background-color: $visitar;
		background-image: url('img/info-practica-visitar-paciente-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-visitar-paciente-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-visitar-paciente.jpg');
			&:hover {
				background-color: lighten($visitar,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-visitar-paciente@2x.jpg');
			}
		}
	}
	&--tramites a {
		background-color: $tramites;
		background-image: url('img/info-practica-tramites-mult.jpg');
		@media #{$retina} {
			background-image: url('img/info-practica-tramites-mult@2x.jpg');
		}
		@supports (background-blend-mode: multiply) {
			background-image: url('img/info-practica-tramites.jpg');
			&:hover {
				background-color: lighten($tramites,20%);
			}
			@media #{$retina} {
				background-image: url('img/info-practica-tramites@2x.jpg');
			}
		}
	}*/

	span {
		position: relative;
		top: 0;
		transition: top .8s cubic-bezier(0.250, 0.460, 0.450, 0.940);
	}
}
